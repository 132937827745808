import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = null;

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyBQnW5nkVLgWLEidPgy8dnz-kkF7Hf9Vyo',
		authDomain: 'cgl-vuc2-test.firebaseapp.com',
		databaseURL: 'https://cgl-vuc2-test.firebaseio.com',
		projectId: 'cgl-vuc2-test',
		storageBucket: 'cgl-vuc2-test.appspot.com',
		messagingSenderId: '764509547250',
		appId: '1:764509547250:web:0399344ff3fe231982fe6b'
	};
}


/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyANHMthiR1zeJJgyoKufilVmJuMqAR9suU',
		authDomain: 'cgl-vuc2-production.firebaseapp.com',
		projectId: 'cgl-vuc2-production',
		storageBucket: 'cgl-vuc2-production.appspot.com',
		messagingSenderId: '1017043344005',
		appId: '1:1017043344005:web:17ed91a9f2198a942e27ec',
		measurementId: 'G-LZTHK496M8'
	};
}



if (firebaseConfig) {
	/* Initialize firebase */
	firebase.initializeApp(firebaseConfig);
	console.log('Firebase initialized');
	
	/* Initialize analytics */
	if (env === 'production') {
		firebase.analytics();
	}
}


export default firebase.firestore;