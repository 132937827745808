

const layoutTexts = {
    gameTitle: 'Titel',
    gameIntro: 'Dette spil går ud på at samle kompetencer og score et job til sidst. Du får point for at tage valg, som passer til din spillerprofil.',
    aboutGame: 'Om spillet',
    onBoardingText: `Du vælger selv hvordan du vil se ud i spillet, hvad du vil være god til og hvad du drømmer om.<br><br>
    <span class="inText-star">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 42 40" style="enable-background:new 0 0 42 40;" xml:space="preserve">
        <style type="text/css">
        .st3{fill:#F1B512;}
        </style>
        <g id="Group_1367" transform="translate(-270.308 -308.711)">
            <g id="Icon_feather-star">
                <path class="st3" d="M303.5,348.5c-0.2,0-0.3,0-0.5-0.1l-11.7-6.2l-11.7,6.2c-0.3,0.2-0.7,0.1-1.1-0.1c-0.3-0.2-0.5-0.6-0.4-1
                    l2.3-13l-9.4-9.2c-0.3-0.3-0.4-0.7-0.3-1c0.1-0.4,0.4-0.6,0.8-0.7l13.1-1.9l5.8-11.8c0.2-0.3,0.5-0.6,0.9-0.6l0,0
                    c0.4,0,0.7,0.2,0.9,0.6l5.8,11.8l13.1,1.9c0.4,0.1,0.7,0.3,0.8,0.7c0.1,0.4,0,0.8-0.3,1l-9.4,9.2l2.3,13c0.1,0.4-0.1,0.8-0.4,1
                    C303.9,348.4,303.7,348.5,303.5,348.5z"/>
            </g>
        </g>
    </svg>
    </span>Stjerner viser hvad du er god til i spillet. Du får flere stjerner ved at uddanne dig.<br><br>
    <span class="inText-heart">
        <svg xmlns="http://www.w3.org/2000/svg" width="51.387" height="44.235" viewBox="0 0 51.387 44.235">
            <path id="Hjerte_01" data-name="Hjerte 01" d="M1081.253,191.854c-5.919,0-13.561,5.261-13.561,15.7,0,5.828,3.681,11.253,8.542,16.06a88.951,88.951,0,0,0,16.416,12.267,1.428,1.428,0,0,0,1.471,0,88.925,88.925,0,0,0,16.417-12.267c4.86-4.807,8.541-10.234,8.541-16.06,0-10.439-7.641-15.7-13.56-15.7a14.688,14.688,0,0,0-12.123,6.157A14.685,14.685,0,0,0,1081.253,191.854Z" transform="translate(-1067.692 -191.853)" fill="#f47920"/>
        </svg>
    </span>Målet er at få livskvalitet. Det får du ved at tage valg og få et job der matcher dine fremtidsdrømme.`,
    cvExplanationText:`Dit CV viser, hvad du er god til, dine fremtidsdrømme og dine point (<span class="inText-heart">
    <svg xmlns="http://www.w3.org/2000/svg" width="51.387" height="44.235" viewBox="0 0 51.387 44.235">
        <path id="Hjerte_01" data-name="Hjerte 01" d="M1081.253,191.854c-5.919,0-13.561,5.261-13.561,15.7,0,5.828,3.681,11.253,8.542,16.06a88.951,88.951,0,0,0,16.416,12.267,1.428,1.428,0,0,0,1.471,0,88.925,88.925,0,0,0,16.417-12.267c4.86-4.807,8.541-10.234,8.541-16.06,0-10.439-7.641-15.7-13.56-15.7a14.688,14.688,0,0,0-12.123,6.157A14.685,14.685,0,0,0,1081.253,191.854Z" transform="translate(-1067.692 -191.853)" fill="#f47920"/>
    </svg>
    </span>).<br><br>
    Du får den bedste score, hvis du ender med et job, der matcher dine drømme.`,
    developedText: 'Spillet er udviklet med støtte fra:',
    choosePathTitle: 'Vælg startsted',
    choosePathMax: ' Vælg 1',
    choosePathText: 'Hvor skal din rejse starte?',
    choosePathTitle2: 'Næste skridt',
    choosePathText2: 'Efter %uddannelse% har nye døre åbnet sig for %name%. Hvad skal være det næste skridt?',
    buildAvatarTitle: 'Byg din avatar',
    buildAvatarText: 'Hvordan skal din spiller se ud?',
    editAvatarTitle: 'Rediger avatar',
    editAvatarText: 'Hvordan skal din spiller se ud?',
    nameAvatarTitle: 'Find på et navn',
    nameAvatarText: 'Hvad skal din spiller hedde?',
    personalSkillsTitle: 'Personlige kompetencer',
    personalSkillsMax: 'Vælg 3 ting',
    personalSkillsText: 'Hvad er %name% god til?',
    professionalSkillsTitle: 'Faglige kompetencer',
    professionalSkillsText: 'Hvilke faglige kompetencer starter %name% med?',
    professionalSkillsMax:'Sæt %stars% stjerner',
    goalsTitle: 'Fremtidsdrømme',
    goalsText: 'Hvilken type arbejdsliv drømmer %name% om?',
    goalsMax:'Vælg 3',
    timeEstimationTitle:'Tid til studier',
    timeEstimationText:'Hvor meget tid kan %name% bruge på at uddanne sig?',
    timeEstimationMin:'2 år',
    timeEstimationMax:'5 år',
    specialChallengesTitle: 'Særlige problemer',
    specialChallengesText:'Har %name% nogle særlige problemer, der kommer til at påvirke job eller uddannelse?',
    specialChallengesMax:'Vælg 1',
    cvTitle: '%names% cv er klar!',
    cvText: 'Nu har du givet %name% kompetencer og drømme, og %names% CV er klar.',
    jobApplicationTitle: '%name% søger job',
    jobApplicationText: 'Stjernerne viser, hvor godt jobbet passer til %name%. Et godt match mellem job og drømme giver bedre livskvalitet (point).',
    jobMatchTitle: 'Jobmatch:',
    kompetenceMatchTitle: 'Kompetencer:',
    goalMatchTitle: 'Fremtidsdrømme:',
    jobDesiredProfessional: 'Ønskede faglige kompetencer',
    jobDesiredPersonal: 'Ønskede personlige kompetencer',
    congratsTitle: 'Sådan!',
    congratsText: 'Det lykkedes %name% at score jobbet. %name% er lidt nervøs, men glæder sig også til de nye udfordringer.',
    clockTitle: 'Tiden går',
    clockText: '%name% knokler med studierne. Det er hårdt, men det er også fedt at lære noget.',
    softSkillPointsText: {
        generic: 'Aktiviteter ved siden af studiet giver %name% ny kompetencer.',
        avu:'Flot! %name% fik stjerner i:',
        eud:'%name% styrkede sine kompetencer og fik stjerner i:',
        eux:'Godt valg! %name% fik stjerner i:',
        hf:'Godt valg! %name% fik stjerner i:',
    },
    livePointsText: 'Det lykkedes %name% at få støtte og fastholde studiet, selvom livet indimellem er ret udfordrende.',
    emptyPersonalText:'Der er ikke blevet tilføjet personlige kompetencer til %names% cv endnu.',
    emptyProfessionalText:'Der er ikke blevet tilføjet faglige kompetencer til %names% cv endnu.',
    emptyGoalsText:'Der er ikke blevet tilføjet fremtidsdrømme til %names% cv endnu.',
    timePassesTitle: 'Ansøgningen er sendt',
    timePassesText: '%name% venter spændt på at høre, hvad arbejdspladsen beslutter.',
    resetGameText: '<strong>Vil du nulstille spillet?,</strong><br> Alle dine input i spillet vil blive slettet, og du skal starte forfra.'
    
};

const jobResultsTexts = {
    successTitle: 'Sådan!',
    successText: 'Det lykkedes %name% at score jobbet. %name% er lidt nervøs, men glæder sig også til de nye udfordringer.',
    failureTitle: 'Desværre',
    failureText: `Det lykkedes ikke %name% at få jobbet i denne omgang.`,
    failureText2: `%name% var ikke stærk nok i de efterspurgte kompetencer.`,
    failureBtn: 'Søg igen'
}

const generalUiTexts = {
    start: 'Start',
    ok: 'OK',
    choose: 'VÆLG',
    abnCv: 'ÅBN CV',
    luk: 'LUK',
    vend: 'VEND',
    sog: 'SØG JOBBET',
    skip: 'skip',
    gem: 'GEM',
    provIgen: 'Prøv igen',
    fortsaet: 'FORTSÆT',
    fortryd: 'FORTRYD',
    nustil: 'NULSTIL'

}

const validationTexts = {
    maximumGeneral: 'Du kan ikke vælge mere end %maximum%. Tryk på ‘ok’ når du har valgt.',
    maxNameChars: 'Navnet må max være 18 tegn',
    cookieMessage: 'Spillet bruger cookies til at gemme dit spilresultat. Ved at trykke ‘Forstået’ accepterer du spillets brug af cookies. <br/><u>Læs mere</u>.',
    cookieMessage2: 'Cookies gemmes lokalt, og du kan til enhver tid slette dem under \'Indstillinger\' på din enhed.<br/><br/>Cookies bruges til at gemme din spilprogression og dit spilresultat, så du ikke behøver at starte forfra, hvis spillet bliver afbrudt, og så du kan sende dit resultat på mail.',
    understood: 'FORSTÅET'
}

const gameCompleteTexts = {
    OK:{
        title: 'OK match', 
        text: 'Det lykkedes at skabe et OK match mellem arbejdsliv og %names% kompetencer og drømme. %name% fik <strong>%points% point</strong>. Prøv igen og se, om du kan få et bedre match og få endnu flere point.'
    },
    godt:{
        title: 'Fantastisk match', 
        text: 'Det lykkedes at skabe et fantastisk match mellem arbejdsliv og %names% kompetencer og drømme. %name% fik <strong>%points% point</strong>. Prøv igen og se, om du kan få et bedre match og få endnu flere point.'
    },
    fantastik:{
        title: 'Perfekt match', 
        text: 'Det lykkedes at skabe et perfekt match mellem arbejdsliv og %names% kompetencer og drømme. %name% fik <strong>%points% point</strong>. Prøv igen og se, om du kan få et bedre match og få endnu flere point.'
    },
    button: 'ÅBN CV'
}

export { layoutTexts, generalUiTexts, validationTexts, jobResultsTexts, gameCompleteTexts };