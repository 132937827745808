import body from 'assets/images/avatar/body.png';
import outline from 'assets/images/avatar/outline.png';
import shadow from 'assets/images/avatar/shadow.png';
import ears2 from 'assets/images/avatar/ear2.png';
import ears3 from 'assets/images/avatar/ear3.png';
import ears4 from 'assets/images/avatar/ear4.png';
import noEars from 'assets/images/avatar/empty.png';
import eyes1 from 'assets/images/avatar/eyes1.png';
import eyes2 from 'assets/images/avatar/eyes2.png';
import eyes3 from 'assets/images/avatar/eyes3.png';
import eyes4 from 'assets/images/avatar/eyes4.png';
import hair1 from 'assets/images/avatar/hair1.png';
import hair2 from 'assets/images/avatar/hair2.png';
import hair3 from 'assets/images/avatar/hair3.png';
import hair4 from 'assets/images/avatar/hair4.png';
import hair5 from 'assets/images/avatar/hair5.png';
import hair6 from 'assets/images/avatar/hair6.png';
import hair7 from 'assets/images/avatar/hair7.png';
import hair8 from 'assets/images/avatar/hair8.png';
import hair9 from 'assets/images/avatar/hair9.png';
import mouth1 from 'assets/images/avatar/mouth1.png';
import mouth2 from 'assets/images/avatar/mouth2.png';
import mouth3 from 'assets/images/avatar/mouth3.png';
import mouth4 from 'assets/images/avatar/mouth4.png';
import mouth5 from 'assets/images/avatar/mouth5.png';
import nose1 from 'assets/images/avatar/nose1.png';
import nose2 from 'assets/images/avatar/nose2.png';
import nose3 from 'assets/images/avatar/nose3.png';
import nose4 from 'assets/images/avatar/nose4.png';
import nose5 from 'assets/images/avatar/nose5.png';
import skin2 from 'assets/images/avatar/skin2.png';
import skin3 from 'assets/images/avatar/skin3.png';
import skin4 from 'assets/images/avatar/skin4.png';
import preeyes1 from 'assets/images/avatar/previews/eyes1.png';
import preeyes2 from 'assets/images/avatar/previews/eyes2.png';
import preeyes3 from 'assets/images/avatar/previews/eyes3.png';
import preeyes4 from 'assets/images/avatar/previews/eyes4.png';
import premouth1 from 'assets/images/avatar/previews/mouth1.png';
import premouth2 from 'assets/images/avatar/previews/mouth2.png';
import premouth3 from 'assets/images/avatar/previews/mouth3.png';
import premouth4 from 'assets/images/avatar/previews/mouth4.png';
import premouth5 from 'assets/images/avatar/previews/mouth5.png';
import prenose1 from 'assets/images/avatar/previews/nose1.png';
import prenose2 from 'assets/images/avatar/previews/nose2.png';
import prenose3 from 'assets/images/avatar/previews/nose3.png';
import prenose4 from 'assets/images/avatar/previews/nose4.png';
import prenose5 from 'assets/images/avatar/previews/nose5.png';
// more expressions
// import presadmouth1 from 'assets/images/avatar/previews/sadmouth1.png';
import sadmouth1 from 'assets/images/avatar/sadmouth1.png';
// import presadmouth2 from 'assets/images/avatar/previews/sadmouth2.png';
import sadmouth2 from 'assets/images/avatar/sadmouth2.png';
// import presadmouth3 from 'assets/images/avatar/previews/sadmouth3.png';
import sadmouth3 from 'assets/images/avatar/sadmouth3.png';
// import presadmouth4 from 'assets/images/avatar/previews/sadmouth4.png';
import sadmouth4 from 'assets/images/avatar/sadmouth4.png';
// import presadmouth5 from 'assets/images/avatar/previews/sadmouth5.png';
import sadmouth5 from 'assets/images/avatar/sadmouth5.png';
// import preannoyedeyes1 from 'assets/images/avatar/previews/annoyedeyes1.png';
import annoyedeyes1 from 'assets/images/avatar/annoyedeyes1.png';
// import preannoyedeyes2 from 'assets/images/avatar/previews/annoyedeyes2.png';
import annoyedeyes2 from 'assets/images/avatar/annoyedeyes2.png';
// import preannoyedeyes3 from 'assets/images/avatar/previews/annoyedeyes3.png';
import annoyedeyes3 from 'assets/images/avatar/annoyedeyes3.png';
// import preannoyedeyes4 from 'assets/images/avatar/previews/annoyedeyes4.png';
import annoyedeyes4 from 'assets/images/avatar/annoyedeyes4.png';
// import preannoyedmouth1 from 'assets/images/avatar/previews/annoyedmouth1.png';
import annoyedmouth1 from 'assets/images/avatar/annoyedmouth1.png';
// import preannoyedmouth2 from 'assets/images/avatar/previews/annoyedmouth2.png';
import annoyedmouth2 from 'assets/images/avatar/annoyedmouth2.png';
// import preannoyedmouth3 from 'assets/images/avatar/previews/annoyedmouth3.png';
import annoyedmouth3 from 'assets/images/avatar/annoyedmouth3.png';
// import preannoyedmouth4 from 'assets/images/avatar/previews/annoyedmouth4.png';
import annoyedmouth4 from 'assets/images/avatar/annoyedmouth4.png';
// import preannoyedmouth5 from 'assets/images/avatar/previews/annoyedmouth5.png';
import annoyedmouth5 from 'assets/images/avatar/annoyedmouth5.png';
// import prehappymouth1 from 'assets/images/avatar/previews/happymouth1.png';
import happymouth1 from 'assets/images/avatar/happymouth1.png';
// import prehappymouth2 from 'assets/images/avatar/previews/happymouth2.png';
import happymouth2 from 'assets/images/avatar/happymouth2.png';
// import prehappymouth3 from 'assets/images/avatar/previews/happymouth3.png';
import happymouth3 from 'assets/images/avatar/happymouth3.png';
// import prehappymouth4 from 'assets/images/avatar/previews/happymouth4.png';
import happymouth4 from 'assets/images/avatar/happymouth4.png';
// import prehappymouth5 from 'assets/images/avatar/previews/happymouth5.png';
import happymouth5 from 'assets/images/avatar/happymouth5.png';
// import presadeyes1 from 'assets/images/avatar/previews/sadeyes1.png';
import sadeyes1 from 'assets/images/avatar/sadeyes1.png';
// import presadeyes2 from 'assets/images/avatar/previews/sadeyes2.png';
import sadeyes2 from 'assets/images/avatar/sadeyes2.png';
// import presadeyes3 from 'assets/images/avatar/previews/sadeyes3.png';
import sadeyes3 from 'assets/images/avatar/sadeyes3.png';
// import presadeyes4 from 'assets/images/avatar/previews/sadeyes4.png';
import sadeyes4 from 'assets/images/avatar/sadeyes4.png';

export interface IMoods {
	neutral: {
		eyes: string;
		mouth: string;
	};
	happy: {
		eyes: string;
		mouth: string;
	};
	sad: {
		eyes: string;
		mouth: string;
	};
	annoyed: {
		eyes: string;
		mouth: string;
	};
}

export interface IAvatarCommons {
	nose: string[];
	hair: string[];
	skin: string[];
}
export interface IAvatarAssets {
	neutral: {
		eyes: string[];
		mouth: string[];
	};
	happy: {
		eyes: string[];
		mouth: string[];
	};
	sad: {
		eyes: string[];
		mouth: string[];
	};
	annoyed: {
		eyes: string[];
		mouth: string[];
	};
	nose: string[];
	hair: string[];
	skin: string[];
}

export interface IAvatarPreviewAssets {
	eyes: string[];
	mouth: string[];
	nose: string[];
	hair: string[];
	skin: string[];
}

const avatarCommonAssets = {
	body: body,
	outline: outline,
	shadow: shadow,
};

const avatarEars: string[] = [ears2, ears3, ears4, noEars];

const avatarAssets: IAvatarAssets = {
	neutral: {
		eyes: [eyes1, eyes2, eyes3, eyes4],
		mouth: [mouth1, mouth2, mouth3, mouth4, mouth5],
	},
	happy: {
		eyes: [eyes1, eyes2, eyes3, eyes4],
		mouth: [happymouth1, happymouth2, happymouth3, happymouth4, happymouth5],
	},
	sad: {
		eyes: [sadeyes1, sadeyes2, sadeyes3, sadeyes4],
		mouth: [sadmouth1, sadmouth2, sadmouth3, sadmouth4, sadmouth5],
	},
	annoyed: {
		eyes: [annoyedeyes1, annoyedeyes2, annoyedeyes3, annoyedeyes4],
		mouth: [annoyedmouth1, annoyedmouth2, annoyedmouth3, annoyedmouth4, annoyedmouth5],
	},
	nose: [nose1, nose2, nose3, nose4, nose5],
	hair: [hair1, hair2, hair3, hair4, hair5, hair6, hair7, hair8, hair9],
	skin: [skin2, skin3, skin4],
};

const avatarAssetsPreviews: IAvatarPreviewAssets = {
	eyes: [preeyes1, preeyes2, preeyes3, preeyes4],
	mouth: [premouth1, premouth2, premouth3, premouth4, premouth5],
	hair: [hair1, hair2, hair3, hair4, hair5, hair6, hair7, hair8, hair9],
	nose: [prenose1, prenose2, prenose3, prenose4, prenose5],
	skin: [skin2, skin3, skin4],
};

export { avatarAssets, avatarCommonAssets, avatarEars, avatarAssetsPreviews };
