import React, {lazy} from 'react';

/**
 * Lazy load all "page" components for code splitting
 */
const LandingPage = lazy(() => { return  import('components/landing-page/landing-page'); });
const AvatarPage = lazy(() => { return  import('components/avatar-page/avatar-page'); });
const ChoosePath = lazy(() => { return  import('components/choose-path-page/choose-path-page'); });
const PersonalSkills = lazy(() => { return  import('components/personal-skills/personal-skills'); });
const ProfessionalSkills = lazy(() => { return  import('components/professional-skills/professional-skills'); });
const Goals = lazy(() => { return  import('components/goals/goals'); });
const TimeEstimation = lazy(() => { return  import('components/time-estimation-page/time-estimation-page'); });
const SpecialChallenges = lazy(() => { return  import('components/special-challenges/special-challenges'); });
const CVReady = lazy(() => { return  import('components/cv-ready/cv-ready'); });
const JobApplication = lazy(() => { return  import('components/job-application/job-application'); });
const JobSuccess = lazy(() => { return  import('components/job-application/job-success'); });
const JobFailure = lazy(() => { return  import('components/job-application/job-failure'); });
const EducationCompleted = lazy(() => { return  import('components/educations/education-completed'); });
const ClockPage = lazy(() => { return  import('components/clock/clock-page'); });
const Avu = lazy(() => { return  import('components/educations/avu'); });
const Hf = lazy(() => { return  import('components/educations/hf'); });
const Eud = lazy(() => { return  import('components/educations/eud'); });
const Eux = lazy(() => { return  import('components/educations/eux'); });
const HigherEducation = lazy(() => { return  import('components/educations/higher'); });
const GameComplete = lazy(() => { return  import('components/game-complete/game-complete'); });

interface IPage {
	id: string;
	component: React.FC;
}

/*
* map the different component according to the page id. There is a goTo Page function in src/store/globalActions
*/
const allPages: IPage[] = [
	{ id: 'landing', component: LandingPage },
	{ id: 'choose-path', component: ChoosePath },
	{ id: 'avatar', component: AvatarPage },
	{ id: 'personal-skills', component: PersonalSkills },
	{ id: 'professional-skills', component: ProfessionalSkills },
	{ id: 'goals', component: Goals },
	{ id: 'time-estimation', component: TimeEstimation },
	{ id: 'special-challenges', component: SpecialChallenges },
	{ id: 'cv-ready', component: CVReady },
	{ id: 'clock', component: ClockPage},
	{ id: 'education-completed', component: EducationCompleted },
	{ id: 'AVU', component:Avu},
	{ id: 'avu-choice1', component:Avu},
	{ id: 'avu-choice2', component:Avu},
	{ id: 'avu-obstacle', component:Avu},
	{ id: 'HF', component:Hf},
	{ id: 'fullHf', component:Hf},
	{ id: 'hfe', component:Hf},
	{ id: 'fullHf-choice1', component:Hf},
	{ id: 'fullHf-choice2', component:Hf},
	{ id: 'hfe-choice1', component:Hf},
	{ id: 'hfe-choice2', component:Hf},
	{ id: 'hf-obstacle', component:Hf},
	{ id: 'go-to-higher', component:Hf},
	{ id: 'higher', component: HigherEducation},
	{ id: 'higher-paths', component: HigherEducation},
	{ id: 'higher-filtered', component: HigherEducation},
	{ id: 'higher-erhversakademi', component: HigherEducation},
	{ id: 'higher-professionsbachelor', component: HigherEducation},
	{ id: 'higher-universitetsuddannelse', component: HigherEducation},
	{ id: 'EUD', component:Eud},
	{ id: 'eud-paths', component:Eud},
	{ id: 'eud-kontor', component:Eud},
	{ id: 'eud-fodevarer', component:Eud},
	{ id: 'eud-omsorg', component:Eud},
	{ id: 'eud-teknologi', component:Eud},
	{ id: 'eud-choice1', component:Eud},
	{ id: 'eud-obstacle', component:Eud},
	{ id: 'EUX', component:Eux},
	{ id: 'eux-paths', component:Eux},
	{ id: 'eux-after-path', component: Eux},
	{ id: 'eux-kontor', component:Eux},
	{ id: 'eux-fodevarer', component:Eux},
	{ id: 'eux-omsorg', component:Eux},
	{ id: 'eux-teknologi', component:Eux},
	{ id: 'eux-choice1', component:Eux},
	{ id: 'eux-obstacle', component:Eux},	
	{ id: 'JOB', component: JobApplication },
	{ id: 'job-success', component: JobSuccess },
	{ id: 'job-failure', component: JobFailure },
	{ id: 'game-complete', component: GameComplete },
];

export default allPages;
