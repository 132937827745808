// import * as actions from 'store/globalActions';
import * as globalTypes from 'store/globalTypes';
import { avatarAssets, avatarCommonAssets, avatarEars } from 'data/avatarData';
import { personalSkills, professionalSkills, goalsData } from 'data/skillsData';
import ears1 from 'assets/images/avatar/ear1.png';
import skin1 from 'assets/images/avatar/skin1.png';
import { gsap } from 'gsap';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import create from 'zustand';
import stateReset from 'store/stateReset';
import appConfig from 'config/app.config';

const initialState = {
	// Players visual representation
	characteristics: {
		neutral: {
			eyes: avatarAssets.neutral.eyes[0],
			mouth: avatarAssets.neutral.mouth[0],
		},
		happy: {
			eyes: avatarAssets.happy.eyes[0],
			mouth: avatarAssets.happy.mouth[0],
		},
		sad: {
			eyes: avatarAssets.sad.eyes[0],
			mouth: avatarAssets.sad.mouth[0],
		},
		annoyed: {
			eyes: avatarAssets.annoyed.eyes[0],
			mouth: avatarAssets.annoyed.mouth[0],
		},
		nose: avatarAssets.nose[0],
		skin: skin1,
		ears: ears1,
		outline: avatarCommonAssets.outline,
		hair: avatarAssets.hair[0],
		shadow: avatarCommonAssets.shadow,
		body: avatarCommonAssets.body,
		name: '',
	},
	// Current page to view
	page: 'landing',
		
	// player points
	points: 0,

	// Current selected education path
	selectedPath: '',

	// Player's current stats (soft skills)
	playerStats: JSON.parse(JSON.stringify(personalSkills)),

	// Player's current skills (professional skills)
	playerSkills: JSON.parse(JSON.stringify(professionalSkills)),

	// Player's current goals (dreams)
	goals: JSON.parse(JSON.stringify(goalsData)),

	// All completed paths
	completedPaths: [],

	// Last completed path
	lastCompleted: '',

	// All completed jobs
	completedJobs: [],

	// Player's previous stats (soft skills)
	prevStats: JSON.parse(JSON.stringify(personalSkills)),

	// Player's previous skills (professional skills)
	prevSkills: JSON.parse(JSON.stringify(professionalSkills)),

	// Player's previous goals (dreams)
	prevGoals: JSON.parse(JSON.stringify(goalsData)),

	// Player's previous completed paths
	prevCompletedPaths: [],

	// Player's previous completed jobs
	prevCompletedJobs: [],

	// Player's "special challenge"
	specialChallenge: '',

	// Cvs availability and visibility
	cv: { unlocked: false, show: false },

	// Cv-previews availability and visibility
	cvPreview: { unlocked: false, show: false },

	// Avatar editor visibility
	showAvatarEditor: false,
		
	// Track first CV's appearance
	firstCvAppearance: true,

	// Track first choose path appearance
	firstChoosePathAppearance: true,
		
	// Total count of applications made (counts towards landing a job)
	applicationsCount: 0,

	// List of job players failed to land
	flaggedJobs: [],

	// HFs direction (path) selection and status
	hfPath: { id: '', name: '', completed: false },

	// EUDs direction (path) selection and status & subpath
	eudPath: { id: '', name: '', subPath: '', completed: false },

	// Videregående Uddannelse's direction (path) selection and status & subpath
	higherPath: { id: '', name: '', subPath: '', completed: false },

	// EUX's direction (path) selection and status & subpath
	euxPath: { id: '', name: '', subPath: '', completed: false },
				
	// Is the device big landscape screen]
	isDesktop: false,

	// Last landed job
	lastSuccessData: { jobName: '', points: 0 },

	// Player's time estimation for completing education
	timeEstimation: { value: 0, quantizedValue: 0 },

	// Player's mood
	mood: 'neutral',

	// Player's quality of life points
	livskvalitets: 0,

	// Player's previous quality of life points
	prevLivskvalitets: 0,

	// Points popups count
	pointsPopupCount: 0,

	// Reset game (and save previous progress)
	saveGames: 0,

	// Set to true to skip avatar creation after game reset
	skipAvatar: false,
};

const localStoragePlayerData = localStorage.getItem(appConfig.playerDataCookieName); 
const init = typeof localStoragePlayerData === 'string' ? JSON.parse(localStoragePlayerData) : initialState;

const useStore = create<globalTypes.IGlobalSate>((set) => {
	return {
		...init,

		// Device information
		deviceInfo: {
			isTouchScreen: false, 
			isMobile: false, 
			isDesktop: false,
			isIos: false, 
			screenSize: {width: 0, height: 0}, 
			orientation: '',
			viewMode: ''
		},

		setDeviceInfo: (deviceInfo: globalTypes.IDeviceInfo) => {
			set((_state) => {
				return { deviceInfo: deviceInfo };
			});
		},

		// Players visual representation
		setCharacteristics: (characteristics: globalTypes.ICharacteristics) => {
			const filterEars = { ...characteristics, ears: avatarEars[3] };
			if (characteristics.hair !== avatarAssets.hair[3]) {
				switch (filterEars.skin) {
				case avatarAssets.skin[0]:
					filterEars.ears = avatarEars[0];
					break;
				case avatarAssets.skin[1]:
					filterEars.ears = avatarEars[1];
					break;
				case avatarAssets.skin[2]:
					filterEars.ears = avatarEars[2];
					break;
				default:
					filterEars.ears = ears1;
				}
			}
			set((_state) => {
				return { characteristics: filterEars };
			});
		},

		// Current page to view
		goToPage: (page: string|null) => {
			if (page === null) return;
			gsap.to(window, { duration: 0.4, scrollTo: { y: 0, x: 0 } });
			set((_state) => {
				return { page: page};
			});
		},

		// player points
		countPoints: () => {
			set((state) => {
				let contPoints = 0;
				Object.keys(state.playerSkills).map((key) => {
					if (state.playerSkills[key as keyof globalTypes.IPlayerSkills].rating > 0) {
						contPoints = contPoints + state.playerSkills[key as keyof globalTypes.IPlayerSkills].rating;
					}
				});
				Object.keys(state.playerStats).map((key) => {
					if (state.playerStats[key as keyof globalTypes.IPlayerStats].rating > 0) {
						contPoints = contPoints + state.playerStats[key as keyof globalTypes.IPlayerStats].rating;
					} else if (
						state.playerStats[key as keyof globalTypes.IPlayerStats].rating === 0 &&
						state.playerStats[key as keyof globalTypes.IPlayerStats].isSelected === true
					) {
						contPoints = contPoints + 1;
					}
				});
				return { points: contPoints };
			});
		},

		// Current selected education path
		setSelectedPath: (selectedPath: string) => {
			set((_state) => {
				return { selectedPath: selectedPath };
			});
		},

		// Player's current stats (soft skills)
		setPlayerStats: (playerStats: globalTypes.IPlayerStats) => {
			set((_state) => {
				return { playerStats: playerStats };
			});
		},

		// Player's current skills (professional skills)
		setPlayerSkills: (playerSkills: globalTypes.IPlayerSkills) => {
			set((_state) => {
				return { playerSkills: playerSkills };
			});
		},

		// Player's current goals (dreams)
		setGoals: (goals: globalTypes.IGoals) => {
			set((_state) => {
				return { goals: goals };
			});
		},

		// All completed paths
		addCompletedPath: (path: string) => {
			set((state) => {
				const currentPathsCompleted = JSON.parse(JSON.stringify(state.completedPaths));
				if (!state.completedPaths.includes(path)) {
					currentPathsCompleted.push(path);
				}
				return { completedPaths: currentPathsCompleted };
			});
		},

		// Last completed path
		setLastCompletedPath: (path: string) => {
			set((_state) => {
				return { lastCompleted: path };
			});
		},

		// All completed jobs
		setCompletedJobs: (job: string) => {
			set((state) => {
				const currentJobsCompleted = JSON.parse(JSON.stringify(state.completedJobs));
				if (!state.completedJobs.includes(job)) {
					currentJobsCompleted.push(job);
				}
				return { completedJobs: currentJobsCompleted };
			});
		},

		// Player's previous stats (soft skills)
		setPrevStats: (prevStats: globalTypes.IPlayerStats) => {
			set((_state) => {
				return { prevStats: prevStats };
			});
		},

		// Player's previous skills (professional skills)
		setPrevSkills: (prevSkills: globalTypes.IPlayerSkills) => {
			set((_state) => {
				return { prevSkills: prevSkills };
			});
		},

		// Player's previous goals (dreams)
		setPrevGoals: (prevGoals: globalTypes.IGoals) => {
			set((_state) => {
				return { prevGoals: prevGoals };
			});
		},

		// Player's previous completed paths
		setPrevCompletedPaths: (paths: string[]) => {
			set((_state) => {
				return { prevCompletedPaths: paths };
			});
		},

		// Player's previous completed jobs
		setPrevCompletedJobs: (jobs: string[]) => {
			set((_state) => {
				return { prevCompletedJobs: jobs };
			});
		},

		// Player's "special challenge"
		setSpecialChallenge: (specialChallenge: string) => {
			set((_state) => {
				return { specialChallenge: specialChallenge };
			});
		},

		// Cvs availability and visibility
		toggleCv: () => {
			set((state) => {
				if (state.cv.unlocked === true) {
					return { cv: { unlocked: true, show: !state.cv.show } };
				}
				return { ...state };
			});
		},
		unlockCv: () => {
			set((state) => {
				return {
					cv: { unlocked: true, show: state.cv.show },
					cvPreview: { unlocked: true, show: state.cv.show },
				};
			});
		},


		// Cv-previews availability and visibility
		toggleCvPreview: () => {
			set((state) => {
				if (state.cv.unlocked === true) {
					return { cvPreview: { unlocked: true, show: !state.cvPreview.show } };
				}
				return { ...state };
			});
		},

		// Avatar editor visibility
		toggleAvatarEditor: () => {
			set((state) => {
				return { showAvatarEditor: !state.showAvatarEditor };
			});
		},

		
		// Track first CV's appearance
		setFirstCvAppearance: () => {
			set((state) => {
				return {
					...state,
					firstCvAppearance: false
				};
			});
		},

		// Track first choose path appearance
		setFirstChoosePathAppearance: () => {
			set((state) => {
				return {
					...state,
					firstChoosePathAppearance: false
				};
			});
		},
		
		// Total count of applications made (counts towards landing a job)
		addApplicationCount: () => {
			set((state) => {
				return { applicationsCount: state.applicationsCount + 1 };
			});
		},

		// List of job players failed to land
		addFlaggedJob: (flaggedJob: string) => {
			set((state) => {
				const newFlaggedJobs = [...state.flaggedJobs, flaggedJob];
				return { flaggedJobs: newFlaggedJobs };
			});
		},

		// HFs direction (path) selection and status
		setHfPath: (hfPath: globalTypes.IHfPath) => {
			set((_state) => {
				return { hfPath: hfPath };
			});
		},

		// EUDs direction (path) selection and status & subpath
		setEudPath: (eudPath: globalTypes.IEudPath) => {
			set((_state) => {
				return { eudPath: eudPath };
			});
		},

		// Videregående Uddannelse's direction (path) selection and status & subpath
		setHigherPath: (higherPath: globalTypes.IHigherPath) => {
			set((_state) => {
				return { higherPath: higherPath };
			});
		},

		// EUX's direction (path) selection and status & subpath
		setEuxPath: (euxPath: globalTypes.IEuxPath) => {
			set((_state) => {
				return { euxPath: euxPath };
			});
		},

				
		// Is the device big landscape screen]
		setIsDesktop: (isDesktop: boolean) => {
			set((_state) => {
				return { isDesktop: isDesktop };
			});
		},

		// Last landed job
		setLastSuccessData: (lastSuccess: globalTypes.ISuccess) => {
			set((_state) => {
				return { lastSuccessData: lastSuccess };
			});
		},

		// Player's time estimation for completing education
		setTimeEstimation: (timeEstimation: number) => {
			let quantizedValue = 0;
			if (timeEstimation > 0 && timeEstimation <= 33) {
				quantizedValue = 1;
			} else if (timeEstimation > 33 && timeEstimation <= 66) {
				quantizedValue = 2;
			} else if (timeEstimation > 66 && timeEstimation <= 100) {
				quantizedValue = 3;
			}
			set((_state) => {
				return { timeEstimation: { value: timeEstimation, quantizedValue: quantizedValue } };
			});
		},

		// Player's mood
		setMood: (mood: string) => {
			set((_state) => {
				return { mood: mood };
			});
		},

		// Player's quality of life points
		addLivskvalitetsPoints: (points: number) => {
			set((state) => {
				return { livskvalitets: state.livskvalitets + points };
			});
		},

		// Player's previous quality of life points
		setPrevLivskvalitetsPoints: (points: number) => {
			set((_state) => {
				return { livskvalitets: points };
			});
		},

		// Points popup's count
		pointsPopupCount: 0,
		addPointsPopupCount: () => {
			set((state) => {
				return { pointsPopupCount: state.pointsPopupCount + 1 };
			});
		},


		// Set whole state
		setGlobalState: (newState: globalTypes.IGlobalSate) => {
			set((_state) => {
				return { ...newState };
			});
		},

		// Reset game (and save previous progress)
		resetGlobalState: () => {
			set((state) => {
				const localStorageGameSaves = localStorage.getItem(appConfig.gameSaves); 
				const localStoragePlayerData = localStorage.getItem(appConfig.playerDataCookieName); 
				const currentGameSave = typeof localStoragePlayerData === 'string' ? JSON.parse(localStoragePlayerData) : {};
				const gameSaves = typeof localStorageGameSaves === 'string' ? JSON.parse(localStorageGameSaves) : {};
				gameSaves[state.saveGames] = { ...currentGameSave };
				localStorage.setItem(appConfig.gameSaves, JSON.stringify(gameSaves));
				return { ...stateReset, page: 'choose-path', saveGames: state.saveGames + 1  };
			});
		}
	};
});

export default useStore;
