import { personalSkills, professionalSkills, goalsData } from 'data/skillsData';
import { jobs } from 'data/jobs';
import { shuffleArray } from 'helpers/arrays-helper';

const jobList: string[] = [];
shuffleArray(jobs).forEach((job) => {
	jobList.push(job.id);
});

/**
 * Reset Global State
 */
const stateReset = {
	page: 'choose-path',
	points: 0,
	selectedPath: '',
	playerStats: JSON.parse(JSON.stringify(personalSkills)),
	playerSkills: JSON.parse(JSON.stringify(professionalSkills)),
	completedPaths: [],
	lastCompleted:'',
	completedJobs: [],
	goals: JSON.parse(JSON.stringify(goalsData)),
	prevCompletedPaths: [],
	prevCompletedJobs: [],
	prevGoals: JSON.parse(JSON.stringify(goalsData)),
	prevStats: JSON.parse(JSON.stringify(personalSkills)),
	prevSkills: JSON.parse(JSON.stringify(professionalSkills)),
	specialChallenge: '',
	cv: { unlocked: true, show: false },
	cvPreview: { unlocked: true, show: false },
	showAvatarEditor: false,
	applicationsCount: 0,
	flaggedJobs: [],
	hfPath: { id: '', name: '', completed: false },
	eudPath: {	id:'', name: '', subPath:'', completed: false },
	higherPath: { id:'', name: '', subPath:'', completed: false },
	euxPath: {	id:'', name: '', subPath:'', completed: false },
	lastSuccessData: { jobName: '', points: 0 },
	timeEstimation: { value: 0, quantizedValue: 0 },
	mood: 'neutral',
	livskvalitets: 0,
	prevLivskvalitets: 0,
	skipAvatar: true,
	pointsPopupCount: 0,
	firstCvAppearance: false,
	firstChoosePathAppearance: false,
};

export default stateReset;
