/* eslint-disable react-hooks/exhaustive-deps */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { useState, useEffect } from 'react';
import firebaseInit from 'firebase-init';
import allPages from 'components/navigation';
import ValidationMessage from 'components/layout/validation-message';
import useStore from 'store/globalStore';
import Loading from 'components/layout/loading';
import { validationTexts } from 'data/ui-texts';
import { getCookie, setCookie, deleteCookie } from 'helpers/cookie-helper';
import {getDeviceInfo, isMobileOrTablet} from 'helpers/device-helper';
import appConfig from 'config/app.config';

const App: React.FC = () => {
	
	/**
	 * Import global state parts needed
	 */
	const [glob, page, deviceInfo, setDeviceInfo] = useStore((state) => {
		return [state, state.page, state.deviceInfo, state.setDeviceInfo];
	});
	// Uncomment those lines to get global state logging, also the appConfig import
	// if (appConfig.env === 'development' || appConfig.env === 'test') {
	// 	console.log('Global state: ', glob);
	// }


	/**
	 * Component did mount
 	*/
	useEffect(() => {
		/* Initialize firebase */
		firebaseInit(); // .then(() => {console.log('Firestore initialized');});


		// Run first time to get device info
		handleWindowResize();

		// Add event listener for window size
		window.addEventListener('resize', handleWindowResize, false); 
		return () => {
			document.removeEventListener('resize', handleWindowResize, false);
		};
	}, []);

	/**
	 * User resized window
	 */
	const handleWindowResize = () => {
		// Get device info
		const [isTouchScreen, isMobile, isIos, screenSize, orientation] = getDeviceInfo();

		// Update device info
		const viewMode = isMobileOrTablet() ? 'portrait' : 'landscape';
		const newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.isTouchScreen = isTouchScreen; 
		newDeviceInfo.isMobile = isMobile;
		newDeviceInfo.isDesktop = !isMobile;
		newDeviceInfo.isIos = isIos;
		newDeviceInfo.screenSize = screenSize;
		newDeviceInfo.orientation = orientation;
		newDeviceInfo.viewMode = viewMode;
		setDeviceInfo(newDeviceInfo);
	};

	/*
	 * Get the current page id from global state and set the PageComponent accordingly
	 */
	const currentPage = allPages.find((item) => {
		return item.id === page;
	});
	const PageComponent: React.FC = currentPage !== undefined ? currentPage.component : allPages[1].component;

	/**
	 * Cookie Validation
	 * */
	const [cookieValidation, setCookieValidation] = useState({
		show: false,
		msg: '',
	});

	// Show cookie popup after 4 seconds
	useEffect(() => {
		setTimeout(() => {
			const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
			if (!cookieConsent) setCookieValidation({ msg: validationTexts.cookieMessage, show: true });
		}, 4000);
	}, []);

	// Close cookie popup on accept
	const handleCloseCookiePopup = () => {
		setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
		setCookieValidation({ msg: '', show: false });
	};

	/**
	 * Update player data on global state change
	 */
	useEffect(() => {
		localStorage.setItem(appConfig.playerDataCookieName, JSON.stringify(glob));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [glob]);


	/**
	 * Clear localStorage
	 */
	const _resetGame = () => {
		deleteCookie(appConfig.cookiesAcceptedCookieName);
		localStorage.removeItem(appConfig.playerDataCookieName);
		window.location.reload();
	};
	
	return (
		<div className={`App ${deviceInfo.isDesktop ? 'desktop' : 'mobile'}`}>
			<React.Suspense fallback={<Loading />}>
				<PageComponent />

				<ValidationMessage
					show={cookieValidation.show}
					clickFunction={() => {
						setCookieValidation({ msg: validationTexts.cookieMessage2, show: true });
					}}
					msg={cookieValidation.msg}
					buttonText={validationTexts.understood}
					buttonFunction={handleCloseCookiePopup}
				/>
			</React.Suspense>
		</div>
	);
};

export default App;
