import React from 'react';
import Button from 'components/layout/button';
import './validation-message.scss';

interface IProps {
	show:boolean;
	msg:string;
	buttonText?: string;
	clickFunction?: ()=>void;
	buttonFunction?: ()=>void;
}

const ValidationMessage:React.FC<IProps> = ({show = false, msg, buttonText = '', clickFunction, buttonFunction}:IProps) => {
	return (
		<div className={`ValidationMessage ${show ? 'showMessage' : ''}`}>
			<div onClick={clickFunction} dangerouslySetInnerHTML={{__html: msg}} />
			{buttonText && buttonFunction && <Button
				text={buttonText}
				clickFunction={buttonFunction}
				isDisabled={false}
				addClasses={['Btn--orange']}
			/>}
		</div>
	);
};

export default ValidationMessage;
