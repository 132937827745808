import React from 'react';
import './button.scss';
interface IProps {
	isDisabled?: boolean;
	text: string;
	addClasses?: string[];
	clickFunction?: (_event:  React.TouchEvent | React.MouseEvent) => void;
}

const Button: React.FC<IProps> = ({ isDisabled, text, addClasses = [''], clickFunction }: IProps) => {

	/**
	 * Generic on click function supplied via props
	 * @param event 
	 */
	const handleClick = (event:  React.TouchEvent | React.MouseEvent) => {
		if (isDisabled) {
			event.preventDefault();
		} else if (clickFunction) {
			event.persist();
			clickFunction(event);
		}
	};

	/**
	 * Add extra classes
	 */
	let className = 'Btn pointer';
	if (addClasses && addClasses.length > 0) {
		addClasses.forEach((c) => {className = `${className} ${c}`;});
	}

	return (
		<div
			className={`${className} ${isDisabled ? 'Btn--isDisabled' : '' }`}
			onClick={(event) => {
				handleClick(event);
			}}
		>
			{text}
		</div>
	);
};

export default Button;
