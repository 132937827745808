import React from 'react';
import './loading.scss';

/**
 * Loading ... animated
 */
const Loading:React.FC = () => {
	return (
		<div className="loading">
			<div className="loading-circle"></div>
			<div className="loading-circle"></div>
			<div className="loading-circle"></div>
		</div>
	);
};

export default Loading;
