let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

const appConfig = {
	env: env,
	name: 'Kom i mål',
	maxGoals: 3,
	maxJobs: 3,
	maxAVUJobs: 6,
	maxStats: 3,
	maxSkillsGeneral:5,
	maxSkillsAVU:4,
	maxNameLength: 18,
	minNameLength: 3,
	animationDurations: {
		star: 0.3,
		tick: 0.3,
		heart: 1.6,
		box:0.3,
	},
	cookiesAcceptedCookieName: 'vuc2_cookies_accepted',
	playerDataCookieName: 'vuc2_player_data',
	gameSaves: 'vuc2_game_saves',
};

export default appConfig;
