const personalSkills = {
	Kreativ: { 
		id: 'Kreativ', name: 'Kreativ', isSelected: false, rating: 0 },
	Stabil: { 
		id: 'Stabil', name: 'Stabil', isSelected: false, rating: 0 },
	Struktureret: { 
		id: 'Struktureret', name: 'Struktureret', isSelected: false, rating: 0 },
	Teamplayer: { 
		id: 'Teamplayer', name: 'Teamplayer', isSelected: false, rating: 0 },
	Omsorgsfuld: { 
		id: 'Omsorgsfuld', name: 'Omsorgsfuld', isSelected: false, rating: 0 },
	Serviceminded: { 
		id: 'Serviceminded', name: 'Serviceminded', isSelected: false, rating: 0 },
	Boglig: { 
		id: 'Boglig', name: 'Boglig', isSelected: false, rating: 0 },
};

const professionalSkills = {
	'Læse/skrive': { 
		id: 'Læse/skrive', name: 'Læse/skrive', rating: 0 },
	Matematik: { 
		id: 'Matematik', name: 'Matematik', rating: 0 },
	Engelsk: { 
		id: 'Engelsk', name: 'Engelsk', rating: 0 },
	Naturfag: { 
		id: 'Naturfag', name: 'Naturfag', rating: 0 },
	Håndværk: { 
		id: 'Håndværk', name: 'Håndværk', rating: 0 },
	Idræt: { 
		id: 'Idræt', name: 'Idræt', rating: 0 },
	Sundhedsvidenskab: { 
		id: 'Sundhedsvidenskab', name: 'Sundhedsvidenskab', rating: 0 },
	Samfundsfag: {
		id: 'Samfundsfag', name: 'Samfundsfag', rating: 0 },
	Kommunikation: { 
		id: 'Kommunikation', name: 'Kommunikation', rating: 0 },
	Pædagogik: { 
		id: 'Pædagogik', name: 'Pædagogik', rating: 0 },
};

const professionalSkillsStartingOptions = ['Læse/skrive', 'Matematik', 'Engelsk', 'Naturvidenskab', 'Håndværk', 'Idræt'];

const goalsData = {
	'Arbejde med hænderne': {
		id: 'Arbejde med hænderne',
		name: 'Arbejde med hænderne',
		isSelected: false,
	},
	'Arbejde i et team': {
		id: 'Arbejde i et team',
		name: 'Arbejde i et team',
		isSelected: false,
	},
	'Arbejde med selvbestemmelse': {
		id: 'Arbejde med selvbestemmelse',
		name: 'Arbejde med selvbestemmelse',
		isSelected: false,
	},
	'Arbejde med høj faglighed': {
		id: 'Arbejde med høj faglighed',
		name: 'Arbejde med høj faglighed',
		isSelected: false,
	},
	'Fleksibel arbejdstid': {
		id: 'Fleksibel arbejdstid',
		name: 'Fleksibel arbejdstid',
		isSelected: false,
	},
	'Arbejde udendørs': {
		id: 'Arbejde udendørs',
		name: 'Arbejde udendørs',
		isSelected: false,
	},
	'Arbejde med mennesker': {
		id: 'Arbejde med mennesker',
		name: 'Arbejde med mennesker',
		isSelected: false,
	},
	'Arbejde der er vigtigt for samfundet': {
		id: 'Arbejde der er vigtigt for samfundet',
		name: 'Arbejde, der er vigtigt for samfundet',
		isSelected: false,
	},
	'Kreativt arbejde': {
		id: 'Kreativt arbejde',
		name: 'Kreativt arbejde',
		isSelected: false,
	},
	'Tjene mange penge': {
		id: 'Tjene mange penge',
		name: 'Tjene mange penge',
		isSelected: false,
	},
	'Hurtigt i job': {
		id: 'Hurtigt i job',
		name: 'Hurtigt i job',
		isSelected: false,
	},
};

const specialChallenges = [
	'Hjemløs',
	'Misbrug',
	'Sygdom',
	'Problemer i familien',
	'Kriminalitet',
	'Dårlig økonomi',
	'Ingen',
];

export { personalSkills, professionalSkills, goalsData, specialChallenges, professionalSkillsStartingOptions };
