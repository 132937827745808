import appConfig from 'config/app.config';

const setCookie = (cname: string, cvalue: string): void => {
	const midnight = new Date();
	midnight.setHours(23, 59, 59, 0);

	const year = new Date();
	year.setTime(year.getTime() + (365 * 24 * 60 * 60 * 1000));

	const expires = 'expires=' + (cname === appConfig.playerDataCookieName ? midnight.toUTCString() : year.toUTCString());
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const getCookie = (cname: string): string => {
	const name = cname + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

const deleteCookie = (cname: string): void => {
	setCookie(cname, '');
};

export {
	setCookie,
	getCookie,
	deleteCookie
};