import Goals from 'components/goals/goals';
import { personalSkills, professionalSkills, goalsData } from './skillsData';

export interface IJob {
	id: string;
	name: string;
	description: string;
	perSkillsNeeded: string[];
	proSkillsNeeded: string[];
	matchingGoals: string[];
	path: string[];
	targetScore: number;
}
const jobs: IJob[] = [
	{
		id: 'Pædagogmedhjælper i vuggestue',
		name: 'Pædagogmedhjælper i vuggestue',
		description: 'Vuggestuen Rødbeden søger en frisk pædagogmedhjælper.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde udendørs'].name, goalsData['Arbejde med mennesker'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'Pædagogmedhjælper i børnehave',
		name: 'Pædagogmedhjælper i børnehave',
		description: 'Børnehaven Rosenhaven søger en aktiv pædagogmedhjælper.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde udendørs'].name, goalsData['Arbejde med mennesker'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'Smart Phønez søger tjekket sælger',
		name: 'Smart Phønez søger tjekket sælger',
		description: 'Telefonsælger søges til hurtige salg i nyopstartet salgsvirksomhed.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Tjene mange penge'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'Stabil lagermedarbejder søges',
		name: 'Stabil lagermedarbejder søges',
		description: 'Vi mangler en hurtig opfylder til vores lager. Er du fuld af drive, kan det være, det er dig!',
		proSkillsNeeded: [professionalSkills['Håndværk'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Hurtigt i job'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'Produktionsmedarbejder til IndRamDet',
		name: 'Produktionsmedarbejder til IndRamDet',
		description: 'Vi søger en motiveret og alsidig medarbejder til produktion af indrammede billeder.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Hurtigt i job'].name, goalsData['Arbejde med hænderne'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'Sød kassedame M/K søges',
		name: 'Sød kassedame M/K søges',
		description: 'I Nice Supermarked har vi brug for en smilende kassemedarbejder. Måske er det dig?',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Hurtigt i job'].name, goalsData['Arbejde i et team'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'Tjener med overskud og hurtige ben',
		name: 'Tjener med overskud og hurtige ben',
		description: 'Er du også træt af dårlig service og fluer i suppen? Hvorfor så ikke vise vores kunder, hvad en rigtig tjener er skabt af. Vi har mange turister, så gode engelskkundskaber er et plus.',
		proSkillsNeeded: [professionalSkills.Engelsk.name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Hurtigt i job'].name, goalsData['Arbejde med mennesker'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'En karklud har også følelser',
		name: 'En karklud har også følelser',
		description: 'Grundig, rar og effektiv rengøringshjælp søges til hyggeligt kontor, hvor der altid er chokolade.',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Hurtigt i job'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'Kok søges til Birgers Bøfhus',
		name: 'Kok søges til Birgers Bøfhus',
		description: 'Positiv og energisk kokkemedhjælper med høje ambitioner og veludviklede smagsløg søges til rar familierestaurant.',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Hurtigt i job'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med hænderne'].name],
		path: ['AVU'],
		targetScore: 5,
	},
	{
		id: 'Calling Miss Moneypenny',
		name: 'Calling Miss Moneypenny',
		description: 'Uddannet og skarp advokatsekretær søges til travlt advokatkontor.',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Boglig.name, personalSkills.Serviceminded.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-kontoruddannelse'],
		targetScore: 5,
	},
	{
		id: 'Fleksibel Lægesekretær',
		name: 'Fleksibel lægesekretær',
		description: 'Vi søger en dygtig og frisk lægesekretær, der kan holde styr på vores patienter.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Boglig.name, personalSkills.Serviceminded.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-kontoruddannelse'],
		targetScore: 5,
	},
	{
		id: 'Hjælp! Skolesekretær søges',
		name: 'Hjælp! Skolesekretær søges',
		description: 'Vores søde skolebørn har bedt om hjælp til at holde styr på deres lærere.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Boglig.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-kontoruddannelse'],
		targetScore: 5,
	},
	{
		id: 'Regnskabsassistent, der kan håndtere mange bolde i luften',
		name: 'Regnskabsassistent, der kan håndtere mange bolde i luften',
		description: 'Vi søger en regnskabsassistent med talforståelse og blik for detaljen.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [
			goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde i et team'].name
		],
		path: ['eud-kontoruddannelse'],
		targetScore: 5,
	},
	{
		id: 'Hold fest på firmaets regning',
		name: 'Hold fest på firmaets regning',
		description: 'Dygtig og erfaren eventkoordinator med flair for fest søges til stort eventfirma.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Struktureret.name],
		matchingGoals: [
			goalsData['Fleksibel arbejdstid'].name,
			goalsData['Arbejde med mennesker'].name,
			goalsData['Kreativt arbejde'].name,
		],
		path: ['eud-eventkoordinator'],
		targetScore: 5,
	},
	{
		id: 'PR-nørd søges til ny spændende stilling',
		name: 'PR-nørd søges til ny spændende stilling',
		description: 'Har du et hoved fuld af finurlige, skæve og anderledes idéer? Så er du måske vores nye medarbejder.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name],
		path: ['eud-eventkoordinator'],
		targetScore: 5,
	},
	{
		id: 'Tivoli Tølløse søger medarbejder',
		name: 'Tivoli Tølløse søger medarbejder',
		description: 'Skal dit nye kontor bevæge sig med 60 km i timen?',
		proSkillsNeeded: [professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde udendørs'].name],
		path: ['eud-eventkoordinator'],
		targetScore: 5,
	},
	{
		id: 'Hotel søger eventkoordinator',
		name: 'Hotel søger eventkoordinator',
		description: 'Er du vores nye kollega i Fest- & Eventafdelingen?',
		proSkillsNeeded: [professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Struktureret.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['eud-eventkoordinator'],
		targetScore: 5,
	},
	{
		id: 'Event og kommunikationskonsulent',
		name: 'Event og kommunikationskonsulent',
		description: 'Er du holdspiller og klar til at koordinere og planlægge uddannelsesmesser og besøg på skoler?',
		proSkillsNeeded: [professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-eventkoordinator'],
		targetScore: 5,
	},
	{
		id: 'Butiksansvarlig til Frisk-Fashion',
		name: 'Butiksansvarlig til Frisk-Fashion',
		description: 'Præsentabel, positiv og serviceminded butiksansvarlig søges til lækker tøjbutik på Strøget.',
		proSkillsNeeded: [professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-business', 'eux-business'],
		targetScore: 5,
	},
	{
		id: 'Handelsassistent til FH Biler',
		name: 'Handelsassistent til FH Biler',
		description: 'Du skal vejlede vores kunder, skrive tilbud og sørge for, at vi får styr på vores salgsstatistik.',
		proSkillsNeeded: [professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-business', 'eux-business'],
		targetScore: 5,
	},
	{
		id: 'Varehusmedarbejder',
		name: 'Varehusmedarbejder',
		description: 'Hold styr på vores lager sammen med 10 søde kollegaer.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['eud-business', 'eux-business'],
		targetScore: 5,
	},
	{
		id: 'Butikschef',
		name: 'Butikschef',
		description: 'Ønsker du en varieret hverdag med stort ansvar? Og brænder du for at rådgive kunder om maling?',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-business', 'eux-business'],
		targetScore: 5,
	},
	{
		id: 'Kunderådgiver hos Alarm Forsikring',
		name: 'Kunderådgiver hos Alarm Forsikring',
		description: 'Brænder du for at rådgive og servicere? Og er du opsøgende og udadvendt? Så er du måske den, vi står og mangler.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Tjene mange penge'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-business', 'eux-business'],
		targetScore: 5,
	},
	{
		id: 'Slagter til Thy Fresh Meat',
		name: 'Slagter til Thy Fresh Meat',
		description: 'Alle kan skære i kød. Men de færreste gør det rigtigt.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-handelsuddannelse'],
		targetScore: 5,
	},
	{
		id: 'Ærlig og erfaren sælger søges',
		name: 'Ærlig og erfaren sælger søges',
		description: 'Har du et stærkt salgsgen og et højt ambitionsniveau? Så er du måske vores nye supersælger.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Kreativ.name],
		matchingGoals: [
			goalsData['Tjene mange penge'].name,
			goalsData['Fleksibel arbejdstid'].name,
			goalsData['Arbejde med selvbestemmelse'].name,
		],
		path: ['eud-handelsuddannelse'],
		targetScore: 5,
	},
	{
		id: 'Vil du være en del af holdet?',
		name: 'Vil du være en del af holdet?',
		description: 'Har du erfaring med bogføring, løn og rapportering samt flair for IT? Så har vi jobbet til dig.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Fleksibel arbejdstid'].name ,goalsData['Arbejde i et team'].name],
		path: ['eud-handelsuddannelse'],
		targetScore: 5,
	},
	{
		id: 'Væksthusgartner til Spiren',
		name: 'Væksthusgartner til Spiren',
		description: 'Har du grønne fingre og elsker både roser og stauder? Så er du måske lige den, vi leder efter.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Håndværk.name, professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Stabil.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde udendørs'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-gartner'],
		targetScore: 5,
	},
	{
		id: 'Fuldtidsstilling som Frilandsgartner',
		name: 'Fuldtidsstilling som frilandsgartner',
		description: 'Få røde kinder, og dyrk planter på friland.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Håndværk.name, professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde udendørs'].name, goalsData['Arbejde med hænderne'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-gartner'],
		targetScore: 5,
	},
	{
		id: 'Bliv Plan2mas nye medarbejder',
		name: 'Bliv Plan2mas\' nye medarbejder',
		description: 'Arbejd blandt planter, træer, bær og smukke blomster.',
		proSkillsNeeded: [professionalSkills.Naturfag.name, professionalSkills.Håndværk.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde udendørs'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-gartner'],
		targetScore: 5,
	},
	{
		id: 'Grøn Gartner til Ejendomsservice Virksomhed',
		name: 'Grøn Gartner til Ejendomsservice Virksomhed',
		description: 'Du vil primært stå for vedligeholdelse af grønne arealer såsom græsslåning, beskæring af buske og træer samt beplantning.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde udendørs'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-gartner'],
		targetScore: 5,
	},
	{
		id: 'Grøn Gartner til Kommune Arealer',
		name: 'Grøn gartner til kommunearealer',
		description: 'Bliver du vores kommende gartner, får du ansvaret for en stor del af kommunens arealer.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde udendørs'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-gartner'],
		targetScore: 5,
	},
	{
		id: 'Centerchef til nyt fitnesscenter ',
		name: 'Centerchef til nyt fitnesscenter ',
		description: 'Har du det bedst i spandex og sneakers? Så er dette job lige noget for dig.',
		proSkillsNeeded: [professionalSkills.Idræt.name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-fitnessuddannelsen'],
		targetScore: 5,
	},
	{
		id: 'Får du klienter til at opnå deres drømmeresultater? Så bliv personlig træner hos Flink Fitness.',
		name: 'Får du klienter til at opnå deres drømmeresultater? Så bliv personlig træner hos Flink Fitness',
		description: 'Har du altid drømt om at arbejde på et krigsskib? Så er det nu, du skal gribe chancen.',
		proSkillsNeeded: [professionalSkills.Idræt.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med mennesker'].name],
		path: ['eud-fitnessuddannelsen'],
		targetScore: 5,
	},
	{
		id: 'Engageret og ansvarsfuld instruktør til aktivitetscenter, der kan råbe selv den vildeste polterabend op.',
		name: 'Engageret og ansvarsfuld instruktør til aktivitetscenter, der kan råbe selv den vildeste polterabend op',
		description: 'Er du positiv, psykisk robust og har begge ben på jorden? Så er du måske vores nye kollega til et barselsvikariat.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Idræt.name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med mennesker'].name],
		path: ['eud-fitnessuddannelsen'],
		targetScore: 5,
	},
	{
		id: 'Madmor/far til Børnehuset Solsikken',
		name: 'Madmor/far til Børnehuset Solsikken',
		description: 'Vores børn er sultne efter sund mad med masser af grønt. Så er du selv lidt af en grøntsagsfreak, så send os et par ord.',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Stabil.name, personalSkills.Kreativ.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-ernaeringsassistent', 'eux-ernaeringsassistent'],
		targetScore: 5,
	},
	{
		id: 'Kok til storkøkken',
		name: 'Kok til storkøkken',
		description: 'Har du lyst til at drukne myten om, at storkøkkener ikke kan lave ordentlig mad i bearnaise, så har vi et fedt job til dig.',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Kreativ.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-ernaeringsassistent', 'eux-ernaeringsassistent'],
		targetScore: 5,
	},
	{
		id: 'Ernæringsassistent til privathospital',
		name: 'Ernæringsassistent til privathospital',
		description: 'Trives du bedst med et højt tempo, hvor ingen dage er ens, men udfordringerne er mange? Så er det måske dig, vi står og mangler.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-ernaeringsassistent', 'eux-ernaeringsassistent'],
		targetScore: 5,
	},
	{
		id: 'Ernæringsassistent',
		name: 'Ernæringsassistent',
		description: 'Vil du være med til at arbejde med forskellige kostformer inden for ældre- og sygehuskosten?',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Kreativt arbejde'].name],
		path: ['eud-ernaeringsassistent', 'eux-ernaeringsassistent'],
		targetScore: 5,
	},
	{
		id: 'Ernæringsassistent til Madservice',
		name: 'Ernæringsassistent til madservice',
		description: 'Har du lyst til at arbejde i et produktionskøkken, hvor der laves mad til pensionister, beboere på plejecenter mv.?',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde med hænderne'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-ernaeringsassistent', 'eux-ernaeringsassistent'],
		targetScore: 5,
	},
	{
		id: 'Er der en franskmand gemt i dig?',
		name: 'Er der en franskmand gemt i dig?',
		description: 'Le cuisses de grenouille leder efter en ny kok, der kan lande os Michelin-stjerne nummer 2.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Serviceminded.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-kok'],
		targetScore: 5,
	},
	{
		id: 'Administrativ blæksprutte til CPH Hotel',
		name: 'Administrativ blæksprutte til CPH Hotel',
		description: 'København har fået et nyt og eksklusivt hotel, og vi har brug for netop dig!',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Engelsk.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name, personalSkills.Serviceminded.name],
		matchingGoals: [ goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-kok'],
		targetScore: 5,
	},
	{
		id: 'Kromutter til Balster Kro',
		name: 'Kromutter til Balster Kro',
		description: 'Er du god til at hygge om gæsterne, kan du lide at bage og ikke bleg for at tage fat? Så er du lige den, vi står og mangler.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Engelsk.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med hænderne'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-kok'],
		targetScore: 5,
	},
	{
		id: 'Uddannet social- og sundhedsassistent',
		name: 'Uddannet social- og sundhedsassistent',
		description: 'Vi søger en social- og sundhedsassistent, der brænder for at arbejde med borgere med demens.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name, professionalSkills.Naturfag.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-socialsundhed', 'eux-socialsundhed'],
		targetScore: 5,
	},
	{
		id: 'Medarbejder med hjertet på rette sted',
		name: 'Medarbejder med hjertet på rette sted',
		description: 'De ældre fortjener omsorg, et smil og al den hjælp, de har brug for. Har du lyst til at være med til at give dem netop det?',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-socialsundhed', 'eux-socialsundhed'],
		targetScore: 5,
	},
	{
		id: 'Plejehjemmet Tusindfryd søge hjemmehjælpere',
		name: 'Plejehjemmet Tusindfryd søger hjemmehjælpere',
		description: 'Vi har brug for dig! Så har du en baggrund som social- og sundhedshjælper, fortsæt med at læse.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-socialsundhed', 'eux-socialsundhed'],
		targetScore: 5,
	},
	{
		id: 'Assistent til klinikken DollarGrin',
		name: 'Assistent til klinikken DollarGrin',
		description: 'Er du serviceminded og storsmilende? Så er du måske vores nye klinikassistent.',
		proSkillsNeeded: [professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-tandklinikassistent'],
		targetScore: 5,
	},
	{
		id: 'Fuldtidsansat klinikassistent',
		name: 'Fuldtidsansat klinikassistent',
		description: 'Vi ønsker os en ny kollega med et højt fagligt niveau og en udadvendt personlighed.',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-tandklinikassistent'],
		targetScore: 5,
	},
	{
		id: 'Klinikassistent til Tandlægeskolen',
		name: 'Klinikassistent til Tandlægeskolen',
		description: 'Har du styr på både tænder og formidling?',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-tandklinikassistent'],
		targetScore: 6,
	},
	{
		id: 'Klinikassistent til øre, næse, halslæge',
		name: 'Klinikassistent til øre-, næse- og halslæge',
		description: 'God lille arbejdsplads med spændende opgaver og højt til loftet.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-hospitalsteknisk'],
		targetScore: 6,
	},
	{
		id: 'Konsultationsygeplejerske',
		name: 'Konsultationssygeplejerske',
		description: 'Positiv konsultationssygeplejerske til 33 timer ugentligt.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name, professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-hospitalsteknisk'],
		targetScore: 6,
	},
	{
		id: 'Medical specialist',
		name: 'Medical specialist',
		description: 'Vil du arbejde med den nyeste teknologi, og brænder du for at gøre en forskel for klinikere og patienter?',
		proSkillsNeeded: [
			professionalSkills.Matematik.name,
			professionalSkills.Sundhedsvidenskab.name,
			professionalSkills['Læse/skrive'].name,
			professionalSkills.Naturfag.name,
		],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name, personalSkills.Stabil.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['eud-hospitalsteknisk'],
		targetScore: 7,
	},
	{
		id: 'Nyuddannet pædagog til udflytterbørnehave',
		name: 'Nyuddannet pædagog til udflytterbørnehave',
		description: 'Kan du bare ikke få nok af børn, og er udelivet lige dig? Så er udflytterbørnehaven Solsorten lige stedet for dig.',
		proSkillsNeeded: [professionalSkills.Pædagogik.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Stabil.name, personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde udendørs'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-paedagogiskassistent'],
		targetScore: 5,
	},
	{
		id: 'Erfaren udviklingskonsulent ',
		name: 'Erfaren udviklingskonsulent ',
		description: 'Vil du være med til at gøre en forskel for unge og deres fritidsliv?',
		proSkillsNeeded: [professionalSkills.Pædagogik.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Omsorgsfuld.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name],
		path: ['eud-paedagogiskassistent'],
		targetScore: 5,
	},
	{
		id: 'Ambitiøs pædagogmedhjælper',
		name: 'Ambitiøs pædagogmedhjælper',
		description: 'Vi tilbyder en arbejdsplads med skønne kollegaer, der alle brænder for at give de unge de bedste forudsætninger.',
		proSkillsNeeded: [professionalSkills.Pædagogik.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['eud-paedagogiskassistent'],
		targetScore: 5,
	},
	{
		id: 'Stilladsmontør med store muller',
		name: 'Stilladsmontør med store muller',
		description: 'Er du vild med mandehørm og et godt kammeratskab? Så er det nu, du skal tage action.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde udendørs'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-smed'],
		targetScore: 5,
	},
	{
		id: 'Jernhesten ApS søger gæv smed',
		name: 'Jernhesten ApS søger gæv smed',
		description: 'Til vores kranproduktion (Svejseafdelinger) søger vi dygtige smede.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med hænderne'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-smed'],
		targetScore: 5,
	},
	{
		id: 'Driftsassistent med stort D',
		name: 'Driftsassistent med stort D',
		description: 'Vil du være med til at forsyne 1250 forbrugere i Jyllinge med varme?',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med hænderne'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-smed'],
		targetScore: 5,
	},
	{
		id: 'Erfaren dækmontør til fuldtidsstilling',
		name: 'Erfaren dækmontør til fuldtidsstilling',
		description: 'Vi søger en robust dækmontør til at servicere vores kunder på de danske motorveje, byggepladser m.m. ',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-mekaniker', 'eux-mekaniker'],
		targetScore: 5,
	},
	{
		id: 'Mekaniker til Kølerkøbing Biler ',
		name: 'Mekaniker til Kølerkøbing Biler ',
		description: 'Brænder du for autobranchen, og motiveres du af at yde en fantastisk service og opleve tilfredse kunder? Så kan dette være dit næste job.',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-mekaniker', 'eux-mekaniker'],
		targetScore: 5,
	},
	{
		id: 'Reservedelsrådgiver til Porsche',
		name: 'Reservedelsrådgiver til Porsche',
		description: 'Har du lyst til at arbejde med reservedele og -tilbehør til fantastiske biler?',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-mekaniker', 'eux-mekaniker'],
		targetScore: 5,
	},
	{
		id: 'Afdelingsleder med drive',
		name: 'Afdelingsleder med drive',
		description: 'Kan du holde styr på vores 17 chauffører og få vores gods frem til tiden? Så er du måske som skabt til jobbet.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Teamplayer.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med mennesker'].name, goalsData['Tjene mange penge'].name],
		path: ['eud-godschauffor'],
		targetScore: 5,
	},
	{
		id: 'Chauffør med stort kørekort',
		name: 'Chauffør med stort kørekort',
		description: 'Kunne du tænke dig at blive en del af en af Danmarks største logistikvirksomheder med fart på hverdagen?',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-godschauffor'],
		targetScore: 5,
	},
	{
		id: 'Transportrådgiver til fuldtidsstilling',
		name: 'Transportrådgiver til fuldtidsstilling',
		description: 'Vil du være en del af et stærkt udekørende team inden for farligt gods og generel transportrådgivning?',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-godschauffor'],
		targetScore: 5,
	},
	{
		id: 'Medhjælper til el-teknisk projekt',
		name: 'Medhjælper til el-teknisk projekt',
		description:
			'Har du lyst til at arbejde på en spændende el-entreprise sammen med gode kollegaer, og er du ikke bange for at tage fat? Så er dette måske jobbet for dig!',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med hænderne'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['eud-vvs-energi', 'eux-vvs-energi'],
		targetScore: 5,
	},
	{
		id: 'Erfaren VVS-montør ',
		name: 'Erfaren VVS-montør ',
		description: 'Vi mangler et par hænder, der også har et godt og erfarent hoved skruet på toppen.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-vvs-energi', 'eux-vvs-energi'],
		targetScore: 6,
	},
	{
		id: 'Elektriker til mindre firma',
		name: 'Elektriker til mindre firma',
		description: 'Vi tilbyder et job med god mulighed for personlig udvikling og medindflydelse.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Struktureret.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-vvs-energi', 'eux-vvs-energi'],
		targetScore: 7,
	},
	{
		id: 'Knivskarp Projektingeniør',
		name: 'Knivskarp projektingeniør',
		description: 'Hvor fedt ville det ikke være at kunne designe et system, der kan skubbe flere tusinde tons eller flytte en fredet bygning i et stykke?',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Engelsk.name, professionalSkills.Håndværk.name],
		perSkillsNeeded: [
			personalSkills.Stabil.name,
			personalSkills.Boglig.name,
			personalSkills.Teamplayer.name,
			personalSkills.Kreativ.name,
			personalSkills.Struktureret.name,
		],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Tjene mange penge'].name],
		path: ['eud-struktor', 'eux-struktor'],
		targetScore: 7,
	},
	{
		id: 'Håndværkstaksator på deltid',
		name: 'Håndværkstaksator på deltid',
		description: 'Bliv ansvarlig for dine egne kunder, og skab drømmejobbet.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Boglig.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['eud-struktor', 'eux-struktor'],
		targetScore: 5,
	},
	{
		id: 'Er du klar på en udfordring?',
		name: 'Er du klar på en udfordring?',
		description: 'Har du lyst til at prøve kræfter med et projekt i udlandet, og synes du, at det kunne være spændende at være i Grønland?',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde udendørs'].name, goalsData['Arbejde med hænderne'].name],
		path: ['eud-struktor', 'eux-struktor'],
		targetScore: 5,
	},
	{
		id: 'Webnørd med en finger på pulsen',
		name: 'Webnørd med en finger på pulsen',
		description: 'Er du en haj til både CMS-systemer og grafiske opgaver?',
		proSkillsNeeded: [
			professionalSkills.Matematik.name,
			professionalSkills['Læse/skrive'].name,
			professionalSkills.Engelsk.name,
			professionalSkills.Kommunikation.name,
		],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['multimediedesigner'],
		targetScore: 5,
	},
	{
		id: 'Marketingkoordinator',
		name: 'Marketingkoordinator',
		description: 'Vil du have ansvaret for dine egne kampagner og koncepter, og er du nysgerrig og opdateret på de nyeste tendenser inden for digital marketing?',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['multimediedesigner'],
		targetScore: 5,
	},
	{
		id: 'Webdesigner ',
		name: 'Webdesigner',
		description: 'Er du en haj til hjemmesider? Så er du måske den rette kandidat til at varetage ansvaret for vores hjemmeside.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['multimediedesigner'],
		targetScore: 5,
	},
	{
		id: 'Laborant søges til barselsvikariat',
		name: 'Laborant søges til barselsvikariat',
		description:
			'Vi arbejder med ekstraktion af nukleinsyrer, genotypning, genekspression og bioinformatik. Er du med os endnu? Så er det måske dig, vi mangler.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['laborant'],
		targetScore: 5,
	},
	{
		id: 'Kend den på knækket... ',
		name: 'Kend den på knækket... ',
		description: 'Til Pølsefabrikken Længere Pølser søges en dygtig produktionsleder.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Stabil.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde i et team'].name],
		path: ['laborant'],
		targetScore: 5,
	},
	{
		id: 'Afdelingsleder til Odense',
		name: 'Afdelingsleder til Odense',
		description: 'Lægemiddelstyrelsen søger engageret og udviklingsorienteret afdelingsleder.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['laborant'],
		targetScore: 5,
	},
	{
		id: 'Drømmer du om at lede IT-projekter?',
		name: 'Drømmer du om at lede IT-projekter?',
		description: 'Søger du et job, som giver mere mening, samtidig med at du får stor fleksibilitet og frihed under ansvar?',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde i et team'].name],
		path: ['laborant'],
		targetScore: 5,
	},
	{
		id: 'Programmør til tech virksomhed',
		name: 'Programmør til tech-virksomhed',
		description: 'Sæt dine programmeringsfærdigheder i spil hos en innovativ virksomhed.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Tjene mange penge'].name, goalsData['Arbejde i et team'].name],
		path: ['datamatiker'],
		targetScore: 5,
	},
	{
		id: 'Forretningsorienteret controller',
		name: 'Forretningsorienteret controller',
		description: 'Er du en skarp udvikler med ambitioner om at udvide din viden gennem nye tekniske udfordringer?',
		proSkillsNeeded: [professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde med høj faglighed'].name, goalsData['Tjene mange penge'].name],
		path: ['datamatiker'],
		targetScore: 5,
	},
	{
		id: 'Informationssikkerhedskoordinator',
		name: 'Informationssikkerhedskoordinator',
		description: 'Vil du være med til at udvikle og styrke Rigsarkivets informationssikkerhed?',
		proSkillsNeeded: [professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['datamatiker'],
		targetScore: 5,
	},
	{
		id: 'Frontend -udvikler',
		name: 'Frontend-udvikler',
		description: 'Vi søger en, som kan hjælpe os med at bygge fantastiske frontend-løsninger i samarbejde med dygtige kollegaer.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde i et team'].name],
		path: ['datamatiker'],
		targetScore: 5,
	},
	{
		id: 'Serviceminded VVS-installatør',
		name: 'Serviceminded VVS-installatør',
		description: 'Vi søger en autoriseret VVS-installatør, som trives i samarbejdet med kunden og sætter en ære i at sikre deres tilfredshed.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Serviceminded.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med hænderne'].name, goalsData['Tjene mange penge'].name],
		path: ['vvs-installator'],
		targetScore: 5,
	},
	{
		id: 'Sælger med teknisk flair',
		name: 'Sælger med teknisk flair',
		description: 'Vil du være med til at sælge til professionelle? Vi søger en tekniker, der har styr på det faglige, til en moden virksomhed.',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['vvs-installator'],
		targetScore: 5,
	},
	{
		id: 'Start egen VVS-virksomhed',
		name: 'Start egen VVS-virksomhed',
		description: 'Du får en afvekslende og udfordrende hverdag med stor frihed og selvstændig opgaveløsning.',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde med hænderne'].name],
		path: ['vvs-installator'],
		targetScore: 5,
	},
	{
		id: 'Bankrådgiver til Lokal Banken',
		name: 'Bankrådgiver til Lokal Banken',
		description: 'Som bankrådgiver får du til opgave at gøre det muligt for vores kunder at realisere deres drømme – både de bevidste og ubevidste.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Stabil.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med mennesker'].name, goalsData['Tjene mange penge'].name],
		path: ['finans'],
		targetScore: 5,
	},
	{
		id: 'Økonomimedarbejder til revisionsfirma',
		name: 'Økonomimedarbejder til revisionsfirma',
		description: 'Vi er et stort revisionsfirma med mange ansatte. Vi søger en mellemleder til økonomiafdelingen, som kan holde styr på løn og personale.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med mennesker'].name, goalsData['Tjene mange penge'].name],
		path: ['finans'],
		targetScore: 5,
	},
	{
		id: 'Product Specialist til Drømmesengen A/S',
		name: 'Product Specialist til Drømmesengen A/S',
		description: 'Vil du være med til at forbedre søvn for flere mennesker hver nat – over hele verden?',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['tekstildesign-handbaerk-formidling'],
		targetScore: 5,
	},
	{
		id: 'Pædagogisk tekstildesigner til ButikUnik',
		name: 'Pædagogisk tekstildesigner til ButikUnik',
		description: 'Har du lyst til at støtte borgere i dagtilbud med arbejdet i vores producerende værksted?',
		proSkillsNeeded: [],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Omsorgsfuld.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med mennesker'].name],
		path: ['tekstildesign-handbaerk-formidling'],
		targetScore: 5,
	},
	{
		id: 'Modedesigner til tøjfirma',
		name: 'Modedesigner til tøjfirma',
		description:
			'Har du lyst til at skabe de designs, som bliver en del af den nye mode? Vi søger en printdesigner, der kan være en del af et stort, veletableret team.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['tekstildesign-handbaerk-formidling'],
		targetScore: 5,
	},
	{
		id: 'Lærer til moderne folkeskole',
		name: 'Lærer til moderne folkeskole',
		description: 'Du skal have fagligheden på plads og have lyst til at afpøve nye metoder og værktøjer.',
		proSkillsNeeded: [professionalSkills.Pædagogik.name, professionalSkills['Læse/skrive'].name, professionalSkills.Matematik.name, professionalSkills.Engelsk.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Kreativt arbejde'].name],
		path: ['folkeskolen'],
		targetScore: 5,
	},
	{
		id: 'Klar til en pædagogisk udfording',
		name: 'Klar til en pædagogisk udfording?',
		description: 'Specialskolen Regnbuen søger en lærer til de ældste elever.',
		proSkillsNeeded: [professionalSkills.Pædagogik.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Kreativt arbejde'].name],
		path: ['folkeskolen'],
		targetScore: 5,
	},
	{
		id: 'Lærer til økologisk friskole',
		name: 'Lærer til økologisk friskole',
		description: 'Vi søger en ildsjæl, der med stort engagement kan blive en drivende kraft på vores skole.',
		proSkillsNeeded: [professionalSkills.Pædagogik.name, professionalSkills['Læse/skrive'].name, professionalSkills.Matematik.name, professionalSkills.Engelsk.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde udendørs'].name, goalsData['Arbejde i et team'].name],
		path: ['folkeskolen'],
		targetScore: 5,
	},
	{
		id: 'Efteskolelærer på Bornholm',
		name: 'Efterskolelærer på Bornholm',
		description: 'Har du lyst til at bidrage til en kultur, hvor elever med mange forskellige baggrunde føler sig både mødt og udfordret?',
		proSkillsNeeded: [professionalSkills.Pædagogik.name, professionalSkills['Læse/skrive'].name, professionalSkills.Matematik.name, professionalSkills.Engelsk.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['folkeskolen'],
		targetScore: 5,
	},
	{
		id: 'Velfunderet og empatisk leder',
		name: 'Velfunderet og empatisk leder',
		description: 'Kan du gå foran og lede pædagogisk udvikling, og formår du at bevare børneperspektivet i en travl hverdag?',
		proSkillsNeeded: [professionalSkills.Pædagogik.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['paedagog'],
		targetScore: 5,
	},
	{
		id: 'Pædagog til Hulahophuset',
		name: 'Pædagog til Hulahophuset',
		description: 'Vil du være med til at skabe det fælles bedste, og er du en god medspiller i arbejdsfællesskabet?',
		proSkillsNeeded: [professionalSkills.Pædagogik.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Omsorgsfuld.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['paedagog'],
		targetScore: 5,
	},
	{
		id: 'Dygtig og engageret dagplejer',
		name: 'Dygtig og engageret dagplejer',
		description: 'Dit hjem skal danne rammen for børn i alderen 0-3 år. Du skal være nysgerrig og fordomsfri, og det enkelte barn skal føle sig set og hørt.',
		proSkillsNeeded: [professionalSkills.Pædagogik.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['paedagog'],
		targetScore: 5,
	},
	{
		id: 'Empatisk og kreativ pædagog',
		name: 'Empatisk og kreativ pædagog',
		description: 'Er du kreativ og empatisk, og er du god til at igangsætte sjove og udviklende aktiviteter for børn i SFO\'en? Så er du måske vores nye kollega.',
		proSkillsNeeded: [professionalSkills.Pædagogik.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name, goalsData['Kreativt arbejde'].name],
		path: ['paedagog'],
		targetScore: 5,
	},
	{
		id: 'Børne- og ungeteam søger socialrådgiver',
		name: 'Børne- og ungeteam søger socialrådgiver',
		description: 'Vi søger dig, der ønsker at gøre en forskel for udsatte børn, unge og deres familier.',
		proSkillsNeeded: [professionalSkills.Samfundsfag.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['socialradgiver'],
		targetScore: 5,
	},
	{
		id: 'Ambitiøs socialrådgiver',
		name: 'Ambitiøs socialrådgiver',
		description: 'Er du en god formidler, benhård, men blød indeni, og er du skarp på korrekt visitation?',
		proSkillsNeeded: [professionalSkills.Samfundsfag.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['socialradgiver'],
		targetScore: 5,
	},
	{
		id: 'Beskæftigelseskonsulenter til Jobcenter',
		name: 'Beskæftigelseskonsulenter til Jobcenter',
		description: 'Brænder du for at vejlede, motivere og skabe gode forløb for ledige, så de kommer tilbage i job?',
		proSkillsNeeded: [professionalSkills.Samfundsfag.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
		path: ['socialradgiver'],
		targetScore: 5,
	},
	{
		id: 'Socialrådgiver til myndighedsafdeling',
		name: 'Socialrådgiver til myndighedsafdeling',
		description: 'Kan du sige paragraffer & livsverden, økonomi & empati og politik & faglighed i samme sætning? Så er du måske vores nye rådgiver på det specialiserede voksenområde.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Samfundsfag.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name, ],
		path: ['socialradgiver'],
		targetScore: 5,
	},
	{
		id: 'Sagsbehandler til Borgerservice',
		name: 'Sagsbehandler til Borgerservice',
		description: 'Brænder du for at yde en god borgerservice, og kan du lide sagsbehandling?',
		proSkillsNeeded: [professionalSkills.Samfundsfag.name],
		perSkillsNeeded: [personalSkills.Boglig.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name],
		path: ['offentlig'],
		targetScore: 5,
	},
	{
		id: 'Udviklingskonsulent',
		name: 'Udviklingskonsulent',
		description: 'Vil du være med til at udvikle vores kommunale driftsopgaver? Stærkt team søger teamplayer.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Samfundsfag.name],
		perSkillsNeeded: [personalSkills.Boglig.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['offentlig'],
		targetScore: 5,
	},
	{
		id: 'Sekretær i Ministeriet for Mad med mere',
		name: 'Sekretær til Ministeriet for Mad med mere',
		description: 'Er du den, der kan skabe balance og overblik i kontorchefens hverdag?',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Samfundsfag.name, professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde i et team'].name, goalsData['Tjene mange penge'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['offentlig'],
		targetScore: 5,
	},
	{
		id: 'Bioanalytiker med laboratorieerfaring',
		name: 'Bioanalytiker med laboratorieerfaring',
		description: 'Vi er et travlt team af bioanalytikere på Klinisk Mikrobiologisk Afdeling, der søger en dygtig kollega.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name, professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['bioanalytiker'],
		targetScore: 5,
	},
	{
		id: 'Bioanalytiker til privatklinik',
		name: 'Bioanalytiker til privatklinik',
		description: 'Bioanalytiker, der kan arbejde selvstændigt og har gode samarbejdsevner.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['bioanalytiker'],
		targetScore: 5,
	},
	{
		id: 'Bioanalytiker til laboratorium i lægepraksis',
		name: 'Bioanalytiker til laboratorium i lægepraksis',
		description: 'Har du lyst til at være en aktiv del af en større lægepraksis med eget laboratorium? Stillingen er på 32 timer/uge.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name, professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name, goalsData['Fleksibel arbejdstid'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, ],
		path: ['bioanalytiker'],
		targetScore: 5,
	},
	{
		id: 'Bioanalytiker til Bloddonor-bank',
		name: 'Bioanalytiker til Bloddonor-bank',
		description: 'Bioanalytiker til mobil Bloddonor-bank søges.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, ],
		path: ['bioanalytiker'],
		targetScore: 5,
	},
	{
		id: 'Sygeplejerske til Mimrebo plejecenter',
		name: 'Sygeplejerske til Mimrebo Plejecenter',
		description: 'Empatisk, moden, målrettet og positiv sygeplejerske.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Teamplayer.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde i et team'].name],
		path: ['sygeplejerske'],
		targetScore: 5,
	},
	{
		id: 'Sygeplejerske til Lægehuset Puls',
		name: 'Sygeplejerske til Lægehuset Puls',
		description: 'Du skal kunne lide at arbejde selvstændigt, tage ansvar og give vores patienter en god og tryg oplevelse.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name, personalSkills.Omsorgsfuld.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['sygeplejerske'],
		targetScore: 5,
	},
	{
		id: 'Sygeplejerske til hjemmesygeplejen',
		name: 'Sygeplejersker til hjemmesygeplejen',
		description: 'Hvidovre Kommune søger sygeplejersker til udekørende aftenvagt.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['sygeplejerske'],
		targetScore: 5,
	},
	{
		id: 'Sygeplejerske til privathospital',
		name: 'Sygeplejerske til privathospital',
		description: 'Der er tale om en fuldtidsstilling med både dag- og aftenvagter.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Omsorgsfuld.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['sygeplejerske'],
		targetScore: 5,
	},
	{
		id: 'Medarbejder til Apoteket',
		name: 'Medarbejder til Apoteket',
		description: 'Du skal sikre, at de rigtige lægemidler er på hylderne og have fokus på patientsikkerhed og lægemiddeløkonomi.',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med mennesker'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['farmaci'],
		targetScore: 5,
	},
	{
		id: 'Farmaceut søges til Pille & Co.',
		name: 'Laboratorieassistent søges til Pille & Co',
		description: 'Vil du gøre en forskel i kvalitetsafdelingen i en dynamisk og spændende virksomhed i medicinalindustrien?',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name],
		path: ['farmaci'],
		targetScore: 5,
	},
	{
		id: 'Jurist til ParaGraffen',
		name: 'Jurist til ParaGraffen',
		description: 'ParaGraffen har vokseværk, og vi søger derfor dygtige og erfarne jurister med speciale inden for familie- og arveretten.',
		proSkillsNeeded: [professionalSkills.Samfundsfag.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Boglig.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['jura'],
		targetScore: 5,
	},
	{
		id: 'Ambitiøs jurist til Fiskeristyrrelsen',
		name: 'Ambitiøs jurist til Fiskeristyrelsen',
		description: 'Jurist, der brænder for forvaltningsret og EU-ret til juridisk team.',
		proSkillsNeeded: [professionalSkills.Engelsk.name, professionalSkills.Samfundsfag.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Boglig.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name],
		path: ['jura'],
		targetScore: 5,
	},
	{
		id: 'Jurist hos Finanstilsynet',
		name: 'Jurist hos Finanstilsynet',
		description: 'Kunne du tænke dig at være med til at føre tilsyn med de danske pengeinstitutter?',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills.Samfundsfag.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Boglig.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['jura'],
		targetScore: 5,
	},
	{
		id: 'Lektor til Institut for Statskundskab',
		name: 'Lektor til Institut for Statskundskab',
		description: 'Skarp formidler med praktisk og relevant undervisningserfaring til Institut for Statskundskab.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills.Samfundsfag.name, professionalSkills['Læse/skrive'].name, professionalSkills.Engelsk.name],
		perSkillsNeeded: [],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['statskundskab'],
		targetScore: 5,
	},
	{
		id: 'Rådgiver til politisk topledelse',
		name: 'Rådgiver til politisk topledelse',
		description:
			'Har du politisk tæft og et skarpt blik for, hvad der rører sig på den politiske dagsorden? Så er du måske den rette til at understøtte rådmanden i det daglige arbejde.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills.Samfundsfag.name, professionalSkills['Læse/skrive'].name, professionalSkills.Engelsk.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde i et team'].name, goalsData['Tjene mange penge'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['statskundskab'],
		targetScore: 5,
	},
	{
		id: 'Analytisk medarbejder med interesse for data og kvantitative analyser',
		name: 'Analytisk medarbejder med interesse for data og kvantitative analyser',
		description: 'Besidder du stærke analytiske egenskaber, og evner du at omsætte store datamængder til forståeligt og kommunikativt materiale?',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills.Samfundsfag.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Boglig.name],
		matchingGoals: [goalsData['Arbejde med høj faglighed'].name, goalsData['Tjene mange penge'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['statskundskab'],
		targetScore: 5,
	},
	{
		id: 'Videnskabelig assistent',
		name: 'Videnskabelig assistent',
		description: 'Har du lyst til at undervise på film- og medievidenskab? Du skal kunne undervise på vores kurser på både engelsk og dansk.',
		proSkillsNeeded: [professionalSkills.Engelsk.name, professionalSkills['Læse/skrive'].name, professionalSkills.Kommunikation.name],
		perSkillsNeeded: [personalSkills.Boglig.name],
		matchingGoals: [goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['film-medievidenskab', 'journalist'],
		targetScore: 5,
	},
	{
		id: 'Redaktør til bladet Økologi Nu',
		name: 'Redaktør til bladet Økologi Nu',
		description: 'Er du en skarp, faglig journalist med flair for strategi, der brænder for at bidrage til en mere bæredygtig fremtid?',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills.Engelsk.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Boglig.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['journalist'],
		targetScore: 5,
	},
	{
		id: 'Mediedesigner1',
		name: 'Mediedesigner',
		description: 'Til videofremstilling til vores vlog søger vi en dynamisk videoproducent, der kan stille skarpt på de unge i en Netflixtid.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['film-medievidenskab', 'journalist'],
		targetScore: 5,
	},
	{
		id: 'Reklamefilm1',
		name: 'Reklamefilm',
		description: 'Har du øje for markedet og kan stille skarpt på forbrugerne? Vi har brug for en producer til fremstilling af reklamefilm til vores nyeste hybridbiler.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Tjene mange penge'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['film-medievidenskab', 'journalist'],
		targetScore: 5,
	},
	{
		id: 'Mediedesigner2',
		name: 'Mediedesigner',
		description: 'Mediedesigner søges til større webdesign-virksomhed, hvor du skal fremstille instruktionsvideoer til ungdomsuddannelser, der laver virtuel undervisning. Du er kreativ og hurtig til at øjne målet for opgaven.',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['film-medievidenskab'],
		targetScore: 5,
	},
	{
		id: 'Beklædningsdesigner',
		name: 'Beklædningsdesigner',
		description: 'Til vores dynamiske modehus søger vi en beklædningsdesigner med sans for alt fra uld til polyester og moderne design.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde i et team'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['design-business'],
		targetScore: 5,
	},
	{
		id: 'Industriel designer',
		name: 'Industriel designer',
		description: 'Har du sans for brug af alt fra træ til metal, er det dig, vi søger til produktudvikling af bæredygtige møbler.',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Kreativ.name],
		matchingGoals: [goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde med hænderne'].name],
		path: ['design-business'],
		targetScore: 5,
	},
	{
		id: 'Indkøber; business to business',
		name: 'Indkøber; business to business',
		description: 'Indkøber til velrenommeret engrosmøbelhus. Vi sælger til mindre boligbutikker, og du skal have sans for, hvad der rører sig på boligindretningsmarkedet.',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Serviceminded.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde i et team'].name],
		path: ['design-business'],
		targetScore: 5,
	},
	{
		id: 'Konstruktionsingeniør',
		name: 'Konstruktionsingeniør',
		description: 'Vi går nye veje - vil du med på holdet? BetonBasserne søger konstruktionsingeniør med stærke tekniske evner.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills.Naturfag.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name, goalsData['Tjene mange penge'].name],
		path: ['ingenior'],
		targetScore: 5,
	},
	{
		id: 'El-Ingeniør',
		name: 'El-Ingeniør',
		description: 'Kan du udvikle arbejdsplaner og budgetter til byggeprojekter, og har du lyst til at arbejde i udlandet?',
		proSkillsNeeded: [professionalSkills['Læse/skrive'].name, professionalSkills.Engelsk.name, professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name, goalsData['Tjene mange penge'].name],
		path: ['ingenior'],
		targetScore: 5,
	},
	{
		id: 'Ingeniør med boldøje',
		name: 'Ingeniør med boldøje',
		description: 'Din rolle bliver at investere, indføre og vedligeholde vores måleudstyr, så kvaliteten er i top.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills['Læse/skrive'].name, professionalSkills.Naturfag.name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Tjene mange penge'].name],
		path: ['ingenior'],
		targetScore: 5,
	},
	{
		id: 'Bygningsingeniør3',
		name: 'Bygningsingeniør',
		description: 'Har du en stærk rådgiver- og projektlederprofil med alsidige erfaringer og kompetencer indenfor byggeri?',
		proSkillsNeeded: [professionalSkills.Kommunikation.name, professionalSkills.Naturfag.name, professionalSkills.Matematik.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name, goalsData['Tjene mange penge'].name],
		path: ['ingenior'],
		targetScore: 5,
	},
	// { TODO: WHY DELETED?
	// 	id: 'Pædagog til børnehus',
	// 	name: 'Pædagog til børnehus',
	// 	description: 'Vi søger dig, der brænder for at arbejde med små børn og har øje for deres behov og deres udvikling.',
	// 	proSkillsNeeded: [professionalSkills.Pædagogik.name],
	// 	perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Omsorgsfuld.name],
	// 	matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
	// 	path: ['paedagogik'],
	// 	targetScore: 5,
	// },
	// {
	// 	id: 'Pædagog til SFO',
	// 	name: 'Pædagog til SFO',
	// 	description: 'Til vores større børn i SFO\'en søger vi en kreativ og empatisk pædagog med gode relationelle evner.',
	// 	proSkillsNeeded: [professionalSkills.Pædagogik.name],
	// 	perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Omsorgsfuld.name],
	// 	matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
	// 	path: ['paedagogik'],
	// 	targetScore: 5,
	// },
	// {
	// 	id: 'Pædagogisk medarbejder',
	// 	name: 'Pædagogisk medarbejder',
	// 	description: 'Ungt handicapkollektiv søger dynamisk, empatisk, relationssøgende pædagogisk medarbejder, der skal hjælpe med at overskue dagen for beboerne.',
	// 	proSkillsNeeded: [professionalSkills.Pædagogik.name],
	// 	perSkillsNeeded: [personalSkills.Teamplayer.name, personalSkills.Omsorgsfuld.name, personalSkills.Struktureret.name],
	// 	matchingGoals: [goalsData['Arbejde med mennesker'].name, goalsData['Arbejde der er vigtigt for samfundet'].name, goalsData['Arbejde i et team'].name],
	// 	path: ['paedagogik'],
	// 	targetScore: 5,
	// },
	{
		id: 'Bygningskonstruktør1',
		name: 'Bygningskonstruktør',
		description: 'Til større licitationssager i offentlige byggerier søger vi omhyggelig og projekterende bygningskonstruktør.',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills.Matematik.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['bygningskonstruktor'],
		targetScore: 5,
	},
	{
		id: 'Bygningskonstruktør2',
		name: 'Bygningskonstruktør',
		description: 'Projekterende bygningskonstruktør søges til vores tegnestue i Tyskland. Du skal have sans for detaljer, kendskab til de nyeste tegneprogrammer og være god til sprog.',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills.Engelsk.name, professionalSkills['Læse/skrive'].name, professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['bygningskonstruktor'],
		targetScore: 5,
	},
	{
		id: 'Bygningskonstruktør3',
		name: 'Bygningskonstruktør',
		description: 'Arkitekttegnestuen søger kreativ og teknisk orienteret bygningskonstruktør til renoveringsopgaver.',
		proSkillsNeeded: [professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med høj faglighed'].name, goalsData['Kreativt arbejde'].name, goalsData['Arbejde med selvbestemmelse'].name],
		path: ['bygningskonstruktor'],
		targetScore: 5,
	},
	{
		id: 'Byggeleder til entreprenørfirma',
		name: 'Byggeleder til entreprenørfirma',
		description: 'Kan du bevare overblikket, og har du sans for processen i større byggerier? Så er du måske vores nye byggeleder.',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills['Læse/skrive'].name, professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['ledelse-informatik'],
		targetScore: 5,
	},
	{
		id: 'Projektleder til tegnestue',
		name: 'Projektleder til tegnestue',
		description: 'Vi søger en dynamisk, kreativ og fleksibel projektleder til ad hoc-opgaver på Kunstmuseet.',
		proSkillsNeeded: [professionalSkills.Håndværk.name,professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Kreativ.name, personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name],
		path: ['ledelse-informatik'],
		targetScore: 5,
	},
	{
		id: 'Bygningsingeniør1',
		name: 'Bygningsingeniør',
		description: 'Tilbudsansvarlig til renoveringsopgaver og større nybyggerier, hvor bæredygtighed er i højsædet. Du skal kunne bevare overblikket og have sans for, hvad der rører sig i miljørigtige byggematerialer.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills.Håndværk.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Tjene mange penge'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name],
		path: ['ledelse-informatik'],
		targetScore: 5,
	},
	{
		id: 'Bygningsingeniør2',
		name: 'Bygningsingeniør',
		description: 'Stærk rådgiver søges til vores tegnestue. Du skal sikre, at både bygherre og underentreprenører kan være sikre på brand- og konstruktionssikkerhed i vores moderne nybyggerier.',
		proSkillsNeeded: [professionalSkills.Håndværk.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde i et team'].name],
		path: ['ledelse-informatik'],
		targetScore: 5,
	},
	{
		id: 'Brygmester til Brushovederne',
		name: 'Brygmester til Brushovederne',
		description: 'Vi søger en brygmester med styr på aroma og kemi til at udvikle vores nye julebryg.',
		proSkillsNeeded: [professionalSkills.Naturfag.name, professionalSkills['Læse/skrive'].name, professionalSkills.Sundhedsvidenskab.name],
		perSkillsNeeded: [personalSkills.Struktureret.name],
		matchingGoals: [goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde i et team'].name],
		path: ['fodevarevidenskab', 'ernaeringogsundhed'],
		targetScore: 5,
	},
	{
		id: 'Underviser hos MediWhoCares',
		name: 'Underviser hos MediWhoCares',
		description: 'Vil du rejse Europa tyndt for at hjælpe vores kunder i medicinalbranchen med at bruge vores softwareprogrammer?',
		proSkillsNeeded: [professionalSkills.Sundhedsvidenskab.name, professionalSkills.Naturfag.name, professionalSkills.Engelsk.name, professionalSkills['Læse/skrive'].name],
		perSkillsNeeded: [],
		matchingGoals: [goalsData['Arbejde med selvbestemmelse'].name, goalsData['Tjene mange penge'].name, goalsData['Fleksibel arbejdstid'].name],
		path: ['fodevarevidenskab','ernaeringogsundhed'],
		targetScore: 5,
	},
	{
		id: 'Forskningsmedarbejder til FoodZymes',
		name: 'Forskningsmedarbejder til FoodZymes',
		description: 'Bliv en del af et team, der udvikler enzymer til brød og kager. Vi arbejder på at forbedre kvaliteten af de færdige produkter og selve bageprocessen.',
		proSkillsNeeded: [professionalSkills.Naturfag.name, professionalSkills.Sundhedsvidenskab.name, professionalSkills['Læse/skrive'].name, professionalSkills.Matematik.name],
		perSkillsNeeded: [personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde med høj faglighed'].name, goalsData['Arbejde med selvbestemmelse'].name, goalsData['Arbejde i et team'].name, goalsData['Tjene mange penge'].name],
		path: ['fodevarevidenskab', 'ernaeringogsundhed'],
		targetScore: 5,
	},
	{
		id: 'Diagnosetekniker søges',
		name: 'Diagnosetekniker søges',
		description: 'Vi mangler en diagnosetekniker til vores topmoderne værksted, som skal samarbejde med værkstedsteamet, så vores kunder kan blive hurtigt kørende igen.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde i et team'].name],
		path: ['autoteknolog'],
		targetScore: 5,
	},
	{
		id: 'Autoteknolog',
		name: 'Autoteknolog',
		description: 'Vi søger snarest muligt en struktureret og teamorienteret tekniker til vores mekaniske værksted i vores travle bilhus.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Stabil.name],
		matchingGoals: [goalsData['Arbejde i et team'].name],
		path: ['autoteknolog'],
		targetScore: 5,
	},
	{
		id: 'Autoteknolog2',
		name: 'Autoteknolog',
		description: 'Du kommer til at arbejde med nogle af landets fineste specialbiler og indgå i et engageret og passioneret team.',
		proSkillsNeeded: [professionalSkills.Matematik.name, professionalSkills.Håndværk.name],
		perSkillsNeeded: [personalSkills.Struktureret.name, personalSkills.Stabil.name, personalSkills.Teamplayer.name],
		matchingGoals: [goalsData['Arbejde i et team'].name],
		path: ['autoteknolog'],
		targetScore: 5,
	},
];

export { jobs };
